import { NgModule } from '@angular/core';
import { customClaims, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { RedirectGuard } from './guards/redirect.guard';
import { AuthenticationComponent } from './modules/authentication/authentication.component';
import { FirebaseComponent } from './modules/firebase/firebase.component';
import { EmailValidationGuardService } from './services/auth/email-validation-guard.service';
import { EmailVerificationService } from './services/auth/email-verification.service';
import { RoleGuardService } from './services/auth/role-guard.service';
import { ErrorNotFoundComponent } from './shared/components/error-not-found/error-not-found.component';
import { LoadingComponent } from './shared/components/loading/loading.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['inspector']);
const inspectorOnly = () =>
  pipe(
    customClaims,
    map((claims) => (claims.role == 'inspector' ? true : ['client']))
  );
const clientOnly = () =>
  pipe(
    customClaims,
    map((claims) => (claims.role == null ? true : ['inspector']))
  );

const routes: Routes = [
  {
    path: 'client',
    loadChildren: () =>
      import('./modules/client/client.module').then((m) => m.ClientModule),
  },
  {
    path: 'inspector',
    canActivate: [AngularFireAuthGuard, EmailValidationGuardService, RoleGuardService],
    data: {
      expectedRole: 'inspector',
      authGuardPipe: inspectorOnly,
    },
    loadChildren: () =>
      import('./modules/inspector/inspector.module').then(
        (m) => m.InspectorModule
    ),
  },
  {
    path: 'email',
    canActivate: [EmailVerificationService],
    component: LoadingComponent,
  },
  {
    path: 'connexion',
    component: AuthenticationComponent,
    data: { type: 'login', authGuardPipe: redirectLoggedInToDashboard },
    canActivate: [RedirectGuard],
  },
  {
    path: 'login',
    component: AuthenticationComponent,
    data: { type: 'login', authGuardPipe: redirectLoggedInToDashboard },
    canActivate: [RedirectGuard],
  },
  {
    path: 'inscription',
    component: AuthenticationComponent,
    data: { type: 'signup', authGuardPipe: redirectLoggedInToDashboard },
    canActivate: [RedirectGuard],
  },
  {
    path: 'signup',
    component: AuthenticationComponent,
    data: { type: 'signup', authGuardPipe: redirectLoggedInToDashboard },
    canActivate: [RedirectGuard],
  },
  {
    path: 'confirmation',
    canActivate: [AngularFireAuthGuard],
    component: AuthenticationComponent,
    data: { type: 'confirmation', authGuardPipe: redirectUnauthorizedToLogin },
  },
  { path: 'firebase', component: FirebaseComponent },
  {
    path: '',
    redirectTo: 'client/recherche',
    pathMatch: 'full',
  },
  { path: '**', component: ErrorNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), TranslateModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
