import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { AuthenticationGraphicsComponent } from 'src/app/shared/modals/authentication-graphics-modal/authentication-graphics/authentication-graphics.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoadingModule } from '../loading/loading.module';
import { AuthenticationComponent } from './authentication.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { LoginComponent } from './components/login/login.component';
import { NewCodeSentComponent } from './components/new-code-sent/new-code-sent.component';
import { PasswordResetConfirmationComponent } from './components/password-reset-confirmation/password-reset-confirmation.component';
import { SignupComponent } from './components/signup/signup.component';
import { PasswordResetComponent } from './modals/password-reset/password-reset.component';
import {BusinessLoginComponent} from "./components/business-login/business-login.component";


const modals = [
  PasswordResetComponent,
  PasswordResetConfirmationComponent,
  NewCodeSentComponent,
];

const components = [
  AuthenticationComponent,
  BusinessLoginComponent,
  LoginComponent,
  ConfirmationComponent,
  SignupComponent,
  // PasswordResetComponent,
  // PasswordResetConfirmationComponent,
  AuthenticationGraphicsComponent,
  // NewCodeSentComponent,

  //Modals
  ...modals
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    TranslateModule,
    PipesModule,
    MaterialModule,
    RouterModule,
    LoadingModule,
    SharedModule,
    FormsModule,
  ],
  entryComponents: modals
})
export class AuthenticationModule { }
