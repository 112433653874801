<ng-container *ngIf="!isLoading; else loading">


    <div class="content" [ngClass]="{ 'smaller' : close }" *ngIf="router.url == '/login' || router.url == '/' ">
        <img src="/assets/IllustrationKiwiz-768x834.png" alt="">
        <h2 [innerHtml]="'authentication.tagline' | translate"></h2>
    </div>
    

    <div class="content" [ngClass]="{ 'smaller' : close }" *ngIf="router.url == '/signup'">
        <img src="/assets/IllustrationKiwiz_inscription.png" alt="">
        <h2 id = "text_sign_up" [innerHtml]="'authentication.tagline_signup' | translate"> </h2>
    </div>

<!--
    <div class="content" [ngClass]="{ 'smaller' : close }" *ngIf="alternateGraphics == null; else customGraphics">
        <h2 [innerHtml]="'authentication.tagline' | translate"></h2>
        <img src="/assets/IllustrationKiwiz-768x834.png" alt="">
    </div>
    <ng-template #customGraphics>
        <div class="content" [ngClass]="{ 'smaller' : close }" [innerHtml]="alternateGraphics.content[translate.currentLang] | safeHtml">
        </div>
    </ng-template>
-->

    <ng-container *ngIf="close">
        <div class="actions -center" align="center">
            <button class="o-button -primary" mat-dialog-close>
                {{ 'general.letsgo' | translate }}</button>
        </div>
    </ng-container>

</ng-container>
<ng-template #loading>
    <app-loading-secondary></app-loading-secondary>
</ng-template>