export const fr = {
  VIN: 'NIV',
  Make: 'Marque',
  Model: 'Modèle',
  Year: 'Année',
  Trim: 'Version',
  Transmission_type: 'Type de transmission',
  Engine: 'Moteur',
  DriveTrain: 'Transmission',
  Fuel_type: 'Type de carburant',
  Odometer: 'Odomètre',
  XX_Number: 'XX (Chiffre)',
  Km_Miles: 'Km / Miles',
  Exterior_color: 'Couleur extérieure',
  City: 'Ville',
  Date: 'Date',
  Structure: 'Structure',
  Frame: 'Châssis',
  Subframe: 'Sous-châssis',
  Undercarriage_rust: 'Rouille du plancher',
  Body: 'Carrosserie',
  Body_condition: 'État de la carrosserie',
  Body_alignment: 'Alignement des panneaux de carrosserie',
  Body_seals: 'Joints',
  Doors: 'Portes',
  Trunk: 'Coffre',
  Roof: 'Toit',
  Convertible_top: 'Toit ouvrant',
  Paint_condition: 'État de la peinture',
  Fuel_tank_cap: 'Bouchon de réservoir du carburant',
  Glasses: 'Vitres',
  Front_windshield: 'Pare-brise avant',
  Rear_windshield: 'Pare-brise arrière',
  Windows: 'Fenêtres',
  Mirrors: 'Rétroviseurs',
  Wipers: 'Essuie-glaces',
  Charger_plugs: 'Prises de charge',
  Position_lights: 'Feux de position',
  Low_beam_lights: 'Feux de croisement',
  High_beam_lights: 'Feux de route',
  Turn_signals: 'Clignotants',
  Reverse_lights: 'Feux de recul',
  Brake_lights: 'Feux de freinage',
  Tires_information: 'Informations sur les pneus',
  Tires_tread_depth: 'Profondeur de la bande de roulement des pneus',
  Wheels_condition: 'État des roues',
  Spare_tire_condition: 'État des pneus de secours',
  Tire_condition: 'État des pneus',
  Tread_depth: 'Profondeur de la bande de roulement',
  Left_front: 'Avant gauche',
  Right_front: 'Avant droit',
  Right_rear: 'Arrière droit',
  Left_rear: 'Arrière gauche',
  Make_model: 'Marque et modèle',
  Dimension: 'Dimensions',
  Season: 'Saison',
  Wheels: 'Roues',
  Spare: 'Roue de secours',
  Computer_diagnosis: 'Diagnostic électronique',
  Engine_oil_level: "Niveau d'huile moteur",
  Engine_oil_leaks: "Fuites d'huile moteur",
  Transmission_leaks: 'Fuites de transmission',
  Brakes: 'Freins',
  Front_brake_maintenance: 'Entretien des freins avant',
  Front_brake_replacement: 'Remplacement des freins avant',
  Rear_brake_maintenance: 'Entretien des freins arrière',
  Rear_brake_replacement: 'Remplacement des freins arrière',
  Brake_fluid_condition: 'État du liquide de frein',
  Brake_fluid_leaks: 'Fuites de liquide de frein',
  Transfer_case_leaks: 'Fuites de la boîte de transfert',
  Differential_leaks: 'Fuites de différentiel',
  Coolant: 'Refroidissement',
  Coolant_condition: 'État du liquide de refroidissement',
  Coolant_fluid_leaks: 'Fuites de liquide de refroidissement',
  Air_filter_condition: 'État du filtre à air',
  Radiator: 'Radiateur',
  Radiator_condition: 'État du radiateur',
  Radiator_cap_sealing: 'Étanchéité du bouchon du radiateur',
  Battery: 'Batterie',
  Voltage: 'Tension',
  Terminal: 'Bornes',
  Cables: 'Câbles',
  Mounting: 'Fixation',
  Alternator: 'Alternateur',
  Cooling_fan_condition: 'État du ventilateur de refroidissement',
  Power_steering: 'Direction assistée',
  Belts: 'Courroies',
  V_belts: 'Courroies en V',
  Accessory_belt: "Courroie d'accessoire",
  Hoses_and_pipes: 'Durites et tuyaux',
  Suspension: 'Suspension',
  Shocks_and_bushings: 'Amortisseurs et silentblocs',
  Ball_joints_and_tie_rods: 'Rotules et biellettes de direction',
  Hand_brake: 'Frein à main',
  CV_joints_and_axles: 'Joint homocinétique et essieux',
  Exhaust_system: "Système d'échappement",
  Mufflers: 'Silencieux',
  Catalytic_converter: 'Catalyseur',
  Pipes: 'Tuyaux',
  Air_conditioning: 'Climatisation',
  A_C: 'A/C',
  Blower_motor: 'Moteur de soufflerie',
  'Test_A/C_Compression': 'Test de compression A/C ',
  Condenser: 'Condenseur',
  Dash: 'Tableau de bord',
  Lights: 'Lumières',
  Gauges: 'Jauges',
  Unusual_odors: 'Odeurs inhabituelles',
  No_cigarette_smell: 'Aucune odeur de cigarette',
  No_animal_smell: 'Aucune odeur animale',
  Cruise_control: 'Régulateur de vitesse',
  Radio_Navigation: 'Radio/Navigation',
  Speaker_sound: 'Son des haut-parleurs',
  Bluetooth: 'Bluetooth',
  Steering_wheel: 'Volant',
  Steering_wheel_condition: 'Condition du volant',
  Steering_wheel_heated: 'Volant chauffé',
  Power_door_locks: 'Verrouillage centralisé des portes',
  Power_windows: 'Vitres électriques',
  Power_mirrors: 'Rétroviseurs électriques',
  Sunroof: 'Toit ouvrant',
  Seats: 'Sièges',
  Seat_condition: 'État des sièges',
  Seat_belts: 'Ceintures de sécurité',
  Heated_seats: 'Sièges chauffants',
  Ventilated_seats: 'Sièges ventilés',
  Power_seats: 'Sièges électriques',
  Reversing_camera: 'Caméra de recul',
  Reverse_parking_sensors: 'Capteurs de stationnement arrière',
  Blind_spot_monitors: "Moniteurs d'angle mort",
  Condition_and_cleaning: 'État et nettoyage',
  Dashboard: 'Tableau de bord',
  Inner_door_panels: 'Panneaux intérieurs de porte',
  Summer_carpets: 'Tapis de sol été',
  Winter_floor_mats: 'Tapis de sol hiver',
  Sun_visors: 'Pare-soleil',
  Horn: 'Klaxon',
  Normal_engine_operating: 'Fonctionnement normal du moteur',
  Normal_transmission_operating: 'Fonctionnement normal de la transmission',
  Normal_gearbox_operating: 'Fonctionnement normal de la boîte de vitesses',
  Normal_differential_operating: 'Fonctionnement normal du différentiel',
  Normal_transfer_case_operating:
    'Fonctionnement normal de la boîte de transfert',
  Normal_odometer_operating: 'Fonctionnement normal du compteur kilométrique',
  Normal_brake_system_operating: 'Fonctionnement normal du système de freinage',
  Normal_steering_operating: 'Fonctionnement normal de la direction',
  Normal_suspension_operating: 'Fonctionnement normal de la suspension',
  Normal_wheel_bearing_operating: 'Fonctionnement normal du roulement de roue',
  Normal_clutch_operating: "Fonctionnement normal de l'embrayage",
  Governmental_sticker: 'Vignette gouvernementale',
  Keys_included: 'Clés incluses',
  Car_maker_manual: 'Manuel du constructeur',
  Maintenance_invoices: "Facture d'entretien",
  Additional_comments: 'Commentaires supplémentaires',
  Vehicle_grade: 'Note du vehicule',
  General_photos: 'Photos générales',
  Esthetic_defects: 'Défauts esthétiques',
  Additional_photos: 'Photos supplémentaires',
  Component_to_inspect_photos: 'Photos des composants à inspecter',
  Component_to_inspect_video: 'Vidéo des composants à inspecter',
  Car_video: 'Vidéo du véhicule',
  Photo_three_quarters_rear_driver_side:
    'Photo trois quart arrière du côté conducteur',
  Photo_three_quarters_rear_passenger_side:
    'Photo trois quart arrière du côté passager',
  Photo_three_quarters_front_driver_side:
    'Photo trois quart avant du côté conducteur',
  Photo_three_quarters_front_passenger_side:
    'Photo trois quart avant du côté passager',
  VIN_windshield: 'VIN sur le pare-brise',
  VIN_door: 'VIN sur la porte',
  License_plate: 'Plaque d’immatriculation',
  Front: 'Avant',
  Driver_side: 'Côté conducteur',
  Passenger_side: 'Côté passager',
  Rear: 'Arrière',
  Undercarriage_front_side: 'Dessous avant',
  Undercarriage_driver_side: 'Dessous côté conducteur',
  Undercarriage_passenger_side: 'Dessous côté passager',
  Undercarriage_rear_side: 'Dessous arrière',
  Front_driver_wheel: 'Roue avant conducteur',
  Front_passenger_wheel: 'Roue avant passager',
  Rear_driver_wheel: 'Roue arrière conducteur',
  Rear_passenger_wheel: 'Roue arrière passager',
  Engine_bay: 'Compartiment moteur',
  Interior_of_the_trunk: 'Intérieur du coffre',
  ODBII_codes: 'Codes ODBII',
  Instrument_cluster_engine_turn_on: 'Tableau de bord moteur allumé',
  VIN_photo: 'Photo du VIN',
  Front_driver_compartment: 'Compartiment avant conducteur',
  Front_passenger_compartment: 'Compartiment avant passager',
  Back_passenger_compartment: 'Compartiment arrière passager',
  Back_driver_compartment: 'Compartiment arrière conducteur',
  Battery_test: 'Test de batterie',
  Alternator_test: 'Test d’alternateur',
  Front_driver_make_model: 'Marque et modèle',
  Front_passenger_make_model: 'Marque et modèle',
  Rear_driver_make_model: 'Marque et modèle',
  Rear_passenger_make_model: 'Marque et modèle',
  Front_driver_year: 'Année',
  Front_passenger_year: 'Année',
  Rear_driver_year: 'Année',
  Rear_passenger_year: 'Année',
  Front_driver_dimension: 'Dimensions',
  Front_passenger_dimension: 'Dimensions',
  Rear_driver_dimension: 'Dimensions',
  Rear_passenger_dimension: 'Dimensions',
  Front_driver_season: 'Saison',
  Front_passenger_season: 'Saison',
  Rear_driver_season: 'Saison',
  Rear_passenger_season: 'Saison',
  Front_driver_tread_depth:
    'Profondeur de sculpture du conducteur du pneu avant',
  Front_passenger_tread_depth:
    'Profondeur de sculpture du passager du pneu avant',
  Rear_driver_tread_depth:
    'Profondeur de sculpture du conducteur du pneu arriere',
  Rear_passenger_tread_depth:
    'Profondeur de sculpture du passager du pneu arriere',
  Computer_diagnosis_code: 'Système de diagnostic',
  Computer_diagnosis_photo: 'Photo du système de diagnostic',
  Cold_start_and_walkaround_video:
    'Vidéo de démarrage à froid et de tour du véhicule',
  Coolant_photo: 'Photo du liquide de refroidissement',
  Floor_mats: 'Tapis de sol',
  Navigation_system: 'Système de navigation',
  Battery_photo: 'Photo de la batterie',
  Structural: 'Structure',
  Tires: 'Pneus',
  Mechanic: 'Mécanique',
  Features: 'Fonctionnalités',
  Road_test: 'Test routier',
  Administratives: 'Administratifs',
  Summer: 'Éte',
  'All-Season': 'Toutes les saisons',
  Winter: 'Hiver',
  Front_driver: 'Avant conducteur',
  Front_passenger: 'Avant passager',
  Rear_driver: 'Arrière conducteur',
  Rear_passenger: 'Arrière passager',
  section: {
    Vehicle_information: 'Informations sur le vehicule',
    Structural: 'Structure',
    Lights: 'Lumières',
    Tires: 'Pneus',
    Mechanic: 'Mécanique',
    Features: 'Fonctionnalités',
    Road_test: 'Test routier',
    Administratives: 'Administratifs',
    Main_menu: 'Menu principal',
    Media: 'Média',
    Summary: 'Résumé',
  },
  tire: {
    name: 'Pneus',
    position: 'Position',
    makeModel: 'Marque et modèle',
    year: 'Année',
    dimension: 'Dimensions',
    season: 'Saison',
    treadDepth: 'Profondeur de la bande de roulement',
    replacement: ' : Remplacement immédiat requis',
    replacementSoon: ' : Remplacement à prévoir bientôt',
    replacementNotSoon: ' : Pas de remplacement requis',
  },
  settings: {
    invalidPhoneNumber: 'Numéro de téléphone invalide',
    invalidEmailAddress: 'Adresse e-mail invalide',
    update: 'Mise à jour',
    cancelChanges: 'Annuler les modifications',
    email: 'Adresse email',
    phoneNumber: 'Numéro de téléphone',
    companyContactInfo: {
      title: "Coordonnées de l'entreprise",
      companyName: "Nom de l'entreprise",
      companyAddress: "Adresse de l'entreprise",
      companyPhoneNumber: "Numéro de téléphone de l'entreprise",
    },
    billingContactInfo: {
      title: 'Coordonnées de facturation',
      address: 'Adresse de facturation',
    },
    repContactInfo: {
      title: 'Coordonnées du représentant',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      email: "Adresse e-mail (Sera utilisée pour les rapports d'inspection)",
    },
  },
  cargoCover: 'Cache bagage',
  general: {
    or: 'ou',
    email: 'Courriel',
    password: 'Mot de passe',
    confirmation: 'Confirmation',
    disconnect: 'Déconnexion',
    section: 'Section',
    administration: 'Administration',
    save: 'Sauvegarder',
    add: 'Ajouter',
    name: 'Nom',
    to: 'à',
    above: 'et plus',
    from: 'De',
    cancel: 'Annuler',
    close: 'fermer',
    greetings: 'Allô',
    'up-to-date': 'Vous êtes à jour! 🎉',
    done: 'Terminer',
    next: 'Suivant',
    'return-to-site': 'Retourner au site',
    other: 'Autre',
    delete: 'Supprimer',
    generic_delete_question: 'Êtes-vous certain de vouloir supprimer ceci?',
    generic_unassign_question: 'Êtes-vous certain désassigner ceci?',
    letsgo: 'Continuer',
    download_link: 'Lien de téléchargement',
    intro_message:
      "<b>OFFRE - Consultation gratuite 🥝</b><br>Tu peux profiter d'un appel privilégié gratuit de 30 minutes avec un conseiller CarInspect avant de souscrire.<br>Direction, le clavardage (bulle rose en bas à droite) 🚀",
    good: 'Oh que oui!',
    bad: 'Pas pour moi!',
    ok: "D'accord!",
    finalizeBooking: 'Finaliser la réservation',
    confirm: 'Confirmer',
    back: 'Retour',
    previous: 'Précédent',
    welcome: 'Bienvenue! 🙏',
    saved: 'Sauvegarde effectuée!',
    'read-more': 'Lire plus',
    display_name_message: "Comment t'appelles-tu ?",
    here_you_go: 'Voilà!',
    a_cool_name: 'Ton nom génial',
    search: 'Recherche',
    next_step: 'Étape suivante',
    alright: "D'accord!",
    warning: 'Attention',
    continue: 'Continuer',
    selected: 'Sélectionné(s)',
    unassign: 'Désassigner',
    yes: 'Oui',
    no: 'Non',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    pay: 'Effectuer le paiement',
    help: "Besoin d'aide?",
    lets_go: "C'est parti!",
    welcome_person: 'Bienvenue',
    waiting_steps: "Voici les étapes qui t'attendent:",
    your_needs: 'Indiquer tes besoins',
    your_fav_vehicules: 'Choisir tes véhicules favoris',
    search_best: 'Lancer la recherche et recevoir les meilleures annonces',
    all_ressources:
      "Accéder à toutes les ressources CarInspect pour acheter en confiance (inspections,assurance,garanties,contrat d'achat entreparticuliers,guides...)",
    logout: 'Déconnexion',
    loadMore: 'Défiler pour voir plus de véhicules.',
    report: 'Rapport',
  },
  firebase: {
    VERIFY_EMAIL: {
      title: 'Vérification du courriel',
      content: 'Ton courriel a été validé avec succès!',
    },
    PASSWORD_RESET: {
      title: 'Réinitialisation du mot de passe',
      content:
        'Remplis les informations ci-dessous pour réinitialiser ton mot de passe 😉',
    },
  },
  errors: {
    disconnect: 'Vous avez été déconnecté par mesure de sécurité',
    forbidden: "Vous n'avez pas accès à cette ressource.",
    'missing-infos': 'Veuillez remplir toutes les informations demandées.',
    'wrong-password': 'Votre courriel/mot de passe est invalide.',
    'user-disabled': 'Votre compte a été désactivé.',
    'page-not-found': {
      title: 'Page introuvable',
      body: 'On ne semble pas trouver la page que vous voulez accéder. Vous pouvez tout de même utiliser nos outils!',
    },
    redirect: {
      website: 'Retourner à notre site web',
      search: 'Trouver ma voiture',
      inspection: 'Réserver une inspection',
    },
    'not-found': "La ressource n'a pas été trouvée",
    'invalid-email': 'Le courriel est invalide',
    'weak-password': 'Votre mot de passe doit contenir 6 caractères',
    'email-already-in-use': 'Compte déjà existant',
    error: 'Une erreur est survenue',
  },
  authentication: {
    policies:
      "En continuant, vous acceptez nos <a href='https://carinspect.com/fr/conditions-of-use/' target='_blank'>Conditions d'utilisation</a>",
    connect: 'Se connecter',
    connect_long: 'Déjà inscrit? <a>Connexion</a>',
    signup: "S'inscrire",
    signup_long: 'Pas encore inscrit? <a>Inscription</a>',
    tagline: "N’achète pas de <span class='overline'>citron</span>!",
    tagline_signup:
      "Ton partenaire de <span class='overline'>confiance</span> pour<br>acheter ta voiture usagée.",
    login: 'Connexion',
    'login-b2b': 'Connexion Entreprise',
    'login-page': {
      connect_with_google: 'Se connecter avec Google',
      connect_with_facebook: 'Continuer avec Facebook',
    },
    'signup-page': {
      signup_with_google: 'Inscrivez-vous avec Google',
      signup_with_facebook: 'Continuer avec Facebook',
      password_confirmation: 'Confirmation',
    },
    confirmation: 'Merci de confirmer ton adresse courriel',
    'confirmation-page': {
      title: 'Merci de confirmer ton adresse courriel',
      'resend-code': 'Envoyer le lien à nouveau',
      message: "Un lien t'a été envoyé à ton adresse courriel",
    },
    forgot_password: "J'ai oublié mon mot de passe",
    password_modal: {
      title: 'Réinitialiser mon mot de passe',
      confirm: 'Réinitialiser',
      email: 'Courriel',
      message: "Un lien t'a été envoyé à ton adresse courriel",
    },
    password_reset_successful: 'Ton mot de passe a été modifié avec succès!',
    new_code_sent: 'Un nouveau code a été envoyé',
    B2B_forgot_password: 'Vous avez oublié votre mot de passe?',
    B2B_contact: ' Contactez nous à',
  },
  client: {
    holiday: 'Joyeuses Fêtes !',
    holiday_description:
      "Veuillez noter que notre service est indisponible jusqu'au 2 janvier 2024 inclusivement.",
    form: {
      otherSpecification: ' Préciser votre réponse svp:',
      defectSpecification: "Préciser l'anomalie svp:",
      recommendation: 'Recommandations: ',
      estimated_cost: 'Prix estimé: ',
    },
    purchased_content:
      '<b>Félicitations 🎉</b> <br> Acheter son véhicule est une aventure importante! Merci pour ta confiance et ton aide dans la guerre des citrons ! 🍋',
    suggestions: {
      needs: "Selon tes besoins, voici les voitures qu'on te suggère :",
      favorites: 'Ma liste de véhicules favoris',
      important:
        "Ajoute des véhicules favoris dans cette liste pour qu'on parte à la recherche des meilleures affaires actuellement sur le marché. Pas de panique : tu peux changer et compléter cette liste avec d'autres modèles de voitures à l'étape suivante",
      add_car: 'Ajoute un véhicule',
    },
    insurance: {
      title: 'Assurance',
    },
    pro: {
      title: 'Pour avoir accès à ce contenu, tu dois être membre pro 👑',
      message: "Pour y avoir accès, continue l'étape à laquelle tu étais",
    },
    menu: {
      kiwiz: 'Mes kiwiz',
      guides: 'Ressources',
      general: 'Accueil',
      admin: 'DASHBOARD ADMIN',
      sequence: "Mes étapes d'achat",
      insurance: 'Assurance',
      preferences: 'Mes préférences',
      favorites: 'Ma sélection',
      profile: 'Profil',
    },
    informations: {
      get_your_kiwiz:
        'Je découvre les meilleures annonces de voitures sur le marché 🎉',
      get_kiwiz_message:
        'Ton <b>conseiller CarInspect</b> recherche actuellement <br> <b>tes potentiels CarInspect (meilleures annonces)</b> disponibles 🥝 <br> Le délai maximal est de 48 heures (jours ouvrables)',
      get_kiwiz_message_ads:
        'Un expert CarInspect est sorti victorieux de sa guerre aux citrons!',
      see_my_kiwiz: 'Voir mes CarInspect',
      continue_my_sequence: 'Continuer mon aventure',
    },
    guides: {
      title: 'Ressources',
      no_guides: "Aucune ressource n'est disponible pour le moment",
      consult: "Consulter l'article",
      download_pdf: 'Télécharger le pdf',
    },
    preferences: {
      modify_criteria:
        'Modifie tes critères de recherche à tout moment pour avoir les meilleurs annonces possibles !',
      consideration:
        " Ceux-ci seront pris en considération dans la prochaine recherche d'annonces que tu lanceras",
      vehicle: 'Véhicule 🚗',
      max_price: 'Prix maximal',
      max_mileage: 'Kilométrage maximal',
      min_year: 'Année minimale',
      max_vehicle_age: '  Âge maximal',
      body_work: 'Type de carroserie',
      seller: 'Vendeur',
      search: 'Recherche',
      postal_code: 'Code Postal',
      manual: 'Manuelle',
      automatic: 'Automatique',
      no_preference: 'Pas de préférences',
      motor_skills: 'Motricité',
      traction_integral: 'Traction intégrale',
      annual_mileage: 'Kilométrage annuel moyen',
      my_priorities: 'Mes priorités',
      criteria: 'Critères',
    },
    model_algo: {
      title_part: {
        title: 'Suggestions de modèles',
        welcome_message: 'Bienvenue dans la section Suggestion de modèles! ',
        instructions:
          'Choisis tes critères et nous te suggérerons les meilleurs véhicules! ',
        ad_encouragement:
          "Tu pourras considérer ceux-ci dans la prochaine recherche d'annonces de véhicule que tu lanceras. ",
        dont_fill_all: "* Psst! Tu n'es pas obligé de tout remplir! ",
        return_website: 'Retour au site web',
      },
      typesList: {
        sedan: 'Berline',
        convertible: 'Cabriolet',
        pickup: 'Camion',
        van: 'Fourgon',
        coupe: 'Coupé',
        hatchback: 'Hayon',
        minivan: 'Fourgonnette',
        wagon: 'Familiale',
        suv: 'VUS',
        roadster: 'Roadster',
        no_preference: 'Pas de préférence',
      },
      priorities: 'Priorités',
      prioritiesList: {
        fuel_consumption: 'Faible consommation',
        driving: 'Plaisir de conduite',
        interior: 'Confort',
        reliability: 'Fiabilité',
        safety: 'Sécurité',
      },
      requirement: 'Exigences',
      equipment: 'Équipements essentiels',
      equipmentList: {
        sunroof: 'Toit ouvrant',
        heated_front_seats: 'Sièges avant chauffants',
        gps: 'GPS',
        aux: 'Entrée audio auxiliaire',
        cd_player: 'Lecteur CD',
        apple_carplay: 'Compatibilité Apple CarPlay',
        lane_departure_warning: 'Avertissement de sortie de voie',
        siriusxm: 'Sirius XM',
        bluetooth: 'Audio Bluetooth',
        remote_engine_start: 'Démarrage à distance',
        'adaptive cruise control': 'Régulateur de vitesse adaptatif',
        forward_collision_warning: 'Avertissement de collision avant',
        emergency_braking: "Freinage d'urgence autonome",
        usbs: 'Ports USB avant',
        bindspot_detection: 'Détection des angles morts',
        no_preference: 'Pas de préférence',
      },
      autonomy: 'Autonomie de la batterie (km)',
      min_year: 'Année minimale',
      max_year: 'Année maximale',
      min_passengers: 'Nombre de passagers minimal',
      engine: 'Moteur',
      engineList: {
        electric: 'Électrique',
        hybrid: 'Hybride',
        gas: 'Essence',
        no_preference: 'Pas de préférence',
      },
      transmissionsList: {
        manuel: 'Manuelle',
        automatic: 'Automatique',
      },
      drivetrain: 'Motricité',
      drivetrainList: {
        traction: 'Traction',
        propulsion: 'Propulsion',
        integral: 'Traction intégrale',
        no_preference: 'Pas de préférence',
      },
      fuel_type: 'Type de carburant',
      fuelList: {
        regular: 'Régulière',
        regular_e85: 'Régulière E85',
        super: 'Super',
        diesel: 'Diesel',
        premium: 'Premium',
        no_preference: 'Pas de préférence',
      },
      your_budget: 'Ton budget',
      result_box: {
        results: 'Résultats',
        description:
          'Clique ici pour voir tes futurs CarInspect potentiels! 🥝',
        button: 'Clique ici pour voir les résultats',
        no_results: 'Désolé, aucun résultat ne correspond à tes critères',
      },
      results: {
        make: 'MARQUE',
        model: 'MODÈLE',
        year: 'ANNÉE',
        score: 'SCORE',
        see_car: 'Voir le véhicule',
      },
    },
    ads: {
      title: 'Tes annonces de CarInspect',
      see_ad: "Consulter l'annonce",
      back: 'Retour à mes annonces',
      vehicle_description: 'Description du véhicule',
      expert_note: 'Notes du conseiller CarInspect',
      client_note: 'Notes personnelles sur le véhicule',
      drivetrain: 'Motricité',
      fuel: 'Carburant',
      consumption: 'Consommation',
      maintenance: 'Maintenance (à venir)',
      purchased: "J'ai acheté ! 🥝",
      view_on_site: "Voir l'annonce",
      vehicle_informations: 'Informations sur le véhicule',
      make: 'Marque',
      model: 'Modèle',
      year: 'Année',
      version: 'Version',
      mileage: 'Odomètre',
      price: 'Prix de vente',
      recommended_drivetrain: 'Motorisation conseillée',
      ratings: 'Notes de nos experts',
      pricing: 'Prix détaillés',
      taxes: 'Taxes',
      warranty: 'Garanties* 36mois/60000km',
      licenses: 'Permis et immatriculation',
      insurance: 'Assurance',
      buying_budget: "Coûts à l'achat",
      yearly_budget: 'Coûts annuels',
      show_warranty: 'Inclure les garanties',
      inspect: 'Faire inspecter 🧑‍🔧',
      my_kiwiz: 'Mes CarInspect',
      search_kiwiz: 'La pêche aux kiwiz a été bonne ! 🥝🚘',
      research_all_platforms:
        'On a pris en considération tes favoris, tes préférences et tes critères, et on a recherché sur toutes les plateformes  les meilleures affaires actuellement sur le marché (notre technologie permet de sélectionner uniquement des voitures à un prix intéressant 💡)',
      discover_announcement:
        'Découvre les annonces trouvées, et fais vite, elles ne seront bientôt plus disponibles !🌟✅',
      hope_its_kiwiz: 'On espère que ce sera un CarInspect ! 🤞',
      kiwiz_ideal:
        "(Pour rappel, un kiwiz est une voiture d'occasion idéale sans défauts cachés, achetée en toute confiance !🙌)",
      next_step: 'Prochaines étapes:',
      call_owner: 'Appeler le propriétaire',
      guide_available: '(guide disponible dans l\'onglet "Ressources")',
      do: 'Faire une',
      mecanical_inspection: 'inspection mécanique',
      assure_its_kiwiz:
        "pour s'assurer que c'est un kiwiz et non pas un citron... (onglet \"Inspections)",
      continue: ' Continuer',
      delete_announcement: "Supprimer l'annonce",
      announcement_of: 'Annonces de ',
      kiwiz_ideal_meskiwiz:
        "Pour rappel, un kiwiz est une voiture d'occasion idéale sans défaut caché, achetée en toute confiance !🙌",
      selected_announcement:
        'Retrouve ci-dessous tes annonces selectionnées et classées selon tes modeles favoris.🚘',
      my_models: 'Mes modèles',
      fav_model:
        "Pour recevoir les meilleurs annonces disponibles sur le marché, tu dois d'abord nous renseigner tes 3 modèles favoris sur la page",
      general: '"Acceuil" ',
      search_best_announcement:
        'et cliquer sur "Rechercher les meilleures annonces".',
      best_deals:
        'Ensuite nous partions à la recherche des meilleurs affaires.🚘',
      add_favorite: 'Ajouter',
      remove_favorite: 'Retirer',
      kiwiz_opinion: "L'opinion de CarInspect",
      technical_sheet: 'Fiche technique',
      price_and_kilometers: 'Prix et kilométrages moyens (par année)',
      average_price: 'Prix moyen',
      average_kilometers: 'Kilométrage moyen',
      remove_from_favorites: 'Enlever des favoris',
      see_form: 'Voir la fiche',
      submission_here: '(fais ta soumission ici)',
    },
    inspections: {
      title: 'Réserver une inspection',
      insurance_inspection: 'Inspection pour assurances',
      complete_inspection: 'Inspection complète',
      pick_inspection:
        "Choisis ci-dessous l'inspection que tu souhaites effectuer.",
      inspection_carried_out: 'Inspections réalisées ',
      find_under_inspection_carried_out:
        'Retrouve ci-dessous les inspections que tu as réalisées',
      car: 'Voiture',

      steps: {
        header: "L'inspection CarInspect, en 4 étapes simples",
        first_step: "1- Souscris à l'inspection ici ",
        first_desc:
          "Le montant de l'inspection te sera prélevé afin de la réserver. Si pour quelconque raison l'inspection ne peut pas avoir lieu, tu seras remboursé(e) intégralement.",
        second_step:
          "2- CarInspect se charge d'organiser un rendez-vous entre le vendeur et l'inspecteur CarInspect  dans les plus brefs délais",
        second_desc: "Tu n'as rien à faire 🤩",
        third_step:
          "3- L'inspecteur CarInspect se déplace et inspecte le véhicule rigoureusement, sur 170 points",
        third_desc:
          "Tu n'as même pas besoin d'être avec lui sur place! Si tu choisis l'inspection \"Sans pépins\", l'inspecteur CarInspect négociera le véhicule à ta place, selon l'état.",
        fourth_step:
          "4- Tu reçois le rapport d'inspection digital qui t'indique si l'inspecteur estime que l'auto est une bonne affaire ou non",
        fourth_desc: 'Adieu les arnaques 💣🙌',
      },
      question_mark: 'Tu ne sais pas quelle inspection choisir ?',
      find_out_more: 'En savoir plus',
      tabs: {
        inspections_report: 'Mes rapports',
        subscribe: 'Réserver',
        no_report: "Tu n'as pas encore de rapport d'inspection",
      },
      mandate_page: {
        mandate: 'Mandats',
        make_model_year: 'Marque/Modèle/Année',
        informations: 'Informations',
        url: 'Coordonnées du vendeur',
        postal_code: 'Code postal',
        status: 'Statut',
        available: 'Disponible',
        confirmed: 'Confirmé',
        accepted: 'Accepté',
        started: 'En cours',
        finished: 'Terminé',
        accept: 'Accepter',
        confirm: 'Confirmer',
        remove: 'Retirer',
        debut: "Débuter l'inspection",
        continue: "Continuer l'inspection",
        made_on: 'réalisée le ',
        completedInspection: 'Inspection réalisée',
        date: "Date de l'inspection",
        changeDate: 'Modifier la date',
        Huile: "Test d'huile",
        typeInspection: "Type d'inspection",
        mandate_inspect: "Mandat d'inspection",
        communicate: 'Communiquez avec le vendeur !',
        time_and_date:
          "Trouver une date et une heure à laquelle l'inspection peut être effectuée.",
        enter_date: "Entrer la date de l'inspection",
        enter_time: "Entrer l'heure de l'inspection",
        open: 'Voir détails',
        reportPb: 'Problème ?',
        inpsectionComplete: 'Inspection standard',
        PoidsLourd: 'Poids Lourd',
        ComposantSpecifique: "Inspection d'un composant spécifique",
        issue: 'Problème technique? Lance une nouvelle inspection ici!',
        confirmDate: "Confirmer la date de l'inspection?",
        infoModal: {
          title: 'Informations',
          inspectionDate: 'Inspection prévue le ',
          sellerInfo: 'Vendeur',
          contactInfo: 'Coordonnées: ',
          adCity: 'Ville: ',
          customerInfo: 'Client',
          lastName: 'Nom: ',
          firstName: 'Prénom: ',
          customerPhone: 'Cellulaire: ',
          kiwizAdminNumber: 'Contact CarInspect (Ludwig): ',
          negoRequest: 'Demande de négociation: ',
          extraRequest: 'Demande supplémentaire: ',
          wantInspectorCall:
            "Ce client souhaite recevoir un appel après l'inspection.",
          callNotNecessary:
            "Ce client ne souhaite pas recevoir un appel après l'inspection.",
          goToUrl: "Voir l'annonce",
        },
        reportModal: {
          title: 'Signaler un problème',
          noResponse: 'Vendeur ne répond pas',
          contactLater: 'Vendeur me recontacte plus tard',
          rescheduleInspection: "Vendeur souhaite décaler la date d'inspection",
          other: 'Autre',
          alreadySold: 'Voiture déjà vendue',
          sellerRefusal: 'Refus du vendeur de faire inspecter',
          back: 'Retour',
          otherInput: 'Précisez le problème que vous rencontrez svp',
          confirm: 'Confirmer',
        },
        badDateModal: {
          title: 'Date invalide',
          message:
            'Vous devez indiquer une date postérieure à la date et le temps courant.',
        },
        modifiedDateReason: {
          title: 'Raison du report',
          meteo: 'Condition météorologique',
          buyer: "Demande de l'acheteur",
          seller: 'Demande du vendeur',
          inspector: "Demande de l'inspecteur",
          other: 'Other',
        },
        newInspectionTypeModal: {
          title: "Quel type d'inspection veux-tu créer?",
          iA: 'Assurance',
          complete: 'Régulière',
        },
      },
      inspector_profile: {
        email: 'Email: ',
        description: 'Description: ',
        addModify: 'Ajouter/modifier des informations',
        zipcode: 'Code postal: ',
        maxTravelTime: 'Temps de déplacement maximal: ',
        lang: 'Préférence de langue: ',
        modifyModal: {
          title: 'Modification du profil',
        },
      },
      reports: {
        title: 'Mes rapports',
        inspection_details: {
          disclaimer:
            "CarInspect est une inspection visuelle. Vous acceptez que si vous achetez un véhicule inspecté par CarInspect, vous êtes entièrement responsable de tous les coûts de réparation et/ou d'entretien de votre véhicule. La liste des Frais à prévoir peut ne pas être exhaustive et les coûts peuvent varier",
          all_rights: 'CarInspect © Tous droits réservés.',
          acces_denied: 'Accès refusé',
          tab_synthesis: {
            waitingForRecommendation: "En attente d'une recommendation",
            noMajorRepairsNeeded: 'Aucune réparation majeure requise',
            synthesis: 'Résumé',
            vehicle_information: 'Informations du véhicule',
            information_on_vehicle: 'INFORMATIONS',
            brand: 'Marque',
            model: 'Modèle',
            version: 'Version',
            year: 'Année',
            mileage: 'Odomètre',
            requires_attention_immediately: 'Attention requise immédiatement ',
            requires_attention_soon: 'Attention requise prochainement',
            grade: {
              perfect_condition:
                'Véhicule en excellent état ne nécessitant aucune réparation majeure.',
              average_condition:
                'Véhicule en bon état nécessitant des réparations mineures/majeures.',
              poor_condition:
                'Véhicule en mauvais état nécessitant des réparations majeures.',
              drive_worthy: 'Sécuritaire',
              unsafe: 'Dangereux',
              showroom: 'Showroom',
            },
            elements_attention: 'Éléments qui nécessitent votre attention',
            elements_attention_disclaimer:
              "Les estimations de coûts fournies sont de nature indicative et sont sujettes à des variations en raison de divers facteurs, notamment le choix des pièces (neuves, usagées, reconstruites), le taux horaire, la localisation, et d'autres éléments pertinents.",
            totalCost: 'Coût total estimé',
            priceNotAvailable: 'Prix ​​non disponible actuellement',
            is_in: 'est en',
            bad: 'mauvaise',
            average: 'moyenne',
            on: 'sur',
            date_of_inspection: "Date de l'inspection",
            time_of_inspection: "Heure de l'inspection",
            city: 'Ville',
            recommended: "Conseillé à l'achat",
            general_comment_inspector: 'Commentaires',
            inspector_kiwiz: 'Inspecteur:',
            first_paragraph:
              "Je suis concepteur mécanique, je travaille dans la sécurisation machine pour l'industrie et j'ai étudié en génie mécanique.",
            second_paragraph:
              'Je suis passionné par tout ce qui roule depuis mon enfance : voitures, camions, motos... je passe une bonne partie de mon temps libre à étoffer mes connaissances en automobile et en sport mécanique!',
            third_paragraph:
              "Si je me suis suis lancé dans l'aventure CarInspect c'est pour partager mon expertise en ingénierie mécanique et accompagner en toute transparence les clients dans le bon choix de leur future voiture.",
            cost_of_repairing: 'Frais à prévoir',
            no_repair_required: "Aucun frais n'est à prévoir",
            required_to_repair: 'Éléments',
            potential_to_repair: 'Potentielles réparations',
            problem: 'Observations',
            cost: 'Coûts',
            vin: 'NIV',
            color: 'Couleur du véhicule',
            interiorColor: "Couleur de l'intérieur",
            // Valeurs des couelurs
            Blanc: 'Blanc',
            Noir: 'Noir',
            Gris: 'Gris',
            Argent: 'Argent',
            Bleu: 'Bleu',
            Rouge: 'Rouge',
            Brun: 'Brun',
            Green: 'Green',
            Jaune: 'Jaune',
            White: 'Blanc',
            Black: 'Noir',
            Gray: 'Gris',
            Silver: 'Argent',
            Blue: 'Bleu',
            Red: 'Rouge',
            Brown: 'Brun',
            Vert: 'Green',
            Yellow: 'Jaune',
            interiorFinish: "Fini de l'intérieur",
            // Finis possibles
            Cuir: 'Cuir',
            'Mi-cuir': 'Mi-cuir',
            Tissu: 'Tissu',
            Vinyle: 'Vinyle',
            Leather: 'Cuir',
            'Half leather': 'Mi-cuir',
            Fabric: 'Tissu',
            Vinyl: 'Vinyle',
            fuel: "Type d'énergie",
            motor: 'Moteur',
            firstHand: 'Véhicule de première main',
            finalOffer: 'Offre finale négociée',
            recommended_purchase: 'Achat recommandé: ',
            transmission: 'Transmission ',
            motricity: 'Motricité ',
            visibleEngineLeak: 'Absence de fuite visible du moteur',
            visibleTransmissionLeak:
              'Absence de fuite visible de la transmission',
            visibleDifferentialLeak: 'Absence de fuite visible du différentiel',
            visibleShockAbsorbersLeak:
              'Absence de fuite au niveau des amortisseurs',
            brakeSystemLeak:
              'Absence de fuite au niveau du système de freinage',
            radiatorHose: 'Durites du radiateur',
            radiator: 'Radiateur de refroidissement',
            chargingSystem: 'Système de charge',
            abnormalSmoke: 'Absence de fumée anormale',
            handBrake: 'Frein à main',
            cruiseControl: 'Régulateur de vitesse',
            sunVisor: 'Pare-soleil',
            steeringWheelAdjustment: 'Ajustement du volant',
            brakeService: 'Service des freins',
            motorManagement: 'Gestiion du moteur',
            regenBraking: 'Freinage régénératif',
            upsale: {
              title: 'Analyse de marché',
              overall: [
                'Parmi les',
                'en vente actuellement, nous avons observé que :',
              ],
              avgIndividuals: 'Prix de vente moyen - entre particuliers :',
              avgMarkte: 'Prix moyen de vente chez concessionnaires :',
              mileage: 'Kilométrage moyen :',
              avgSellTime: ['Délai moyen de vente:', 'jours'],
              taxe: 'taxes incluses',
            },
            opinion: {
              title: "L'avis CarInspect",
              intro:
                'Après avoir effectué une inspection minutieuse, voici mes conclusions sur le véhicule inspecté:',
              aesthetic: {
                Excellent:
                  "La carrosserie, l'intérieur et la propreté du véhicule sont en parfait état et rien n'est à signaler.",
                Bon: 'Le véhicule présente peu de défauts esthétiques.',
                Good: 'Le véhicule présente peu de défauts esthétiques.',
                Moyen:
                  'Le véhicule présente plusieurs défauts esthétiques (je vous invite à consulter la deuxième page du rapport)',
                Average:
                  'Le véhicule présente plusieurs défauts esthétiques (je vous invite à consulter la deuxième page du rapport)',
                Mauvais:
                  "L'état esthétique du véhicule a été négligé au cours du temps et l’investissement pour y remédier serait très important.",
                Bad: "L'état esthétique du véhicule a été négligé au cours du temps et l’investissement pour y remédier serait très important.",
              },
              mechanical: {
                Excellent:
                  'Le véhicule ne présente aucun défaut mécanique et roule comme au premier jour.',
                Bon: "L'état mécanique du véhicule est correct et peu de choses sont à prévoir dessus.",
                Good: "L'état mécanique du véhicule est correct et peu de choses sont à prévoir dessus.",
                Moyen:
                  'La mécanique du véhicule n’est pas parfaite et plusieurs opérations seront nécessaires pour en profiter pleinement.',
                Average:
                  'La mécanique du véhicule n’est pas parfaite et plusieurs opérations seront nécessaires pour en profiter pleinement.',
                Mauvais:
                  'La mécanique du véhicule est en mauvais état et/ou a été mal menée. Beaucoup de frais sont à prévoir.',
                Bad: 'La mécanique du véhicule est en mauvais état et/ou a été mal menée. Beaucoup de frais sont à prévoir.',
              },
              functional: {
                Excellent:
                  "Tous les équipements à bord fonctionnent parfaitement et rien n'est à prévoir.",
                Bon: 'Les équipements à bord sont pour la plupart fonctionnels et peu d’interventions seront nécessaires.',
                Good: 'Les équipements à bord sont pour la plupart fonctionnels et peu d’interventions seront nécessaires.',
                Moyen:
                  'Plusieurs interventions vont être nécessaires pour pouvoir profiter pleinement des équipements et/ou des différentes fonctionnalités du véhicule.',
                Average:
                  'Plusieurs interventions vont être nécessaires pour pouvoir profiter pleinement des équipements et/ou des différentes fonctionnalités du véhicule.',
                Mauvais:
                  "Attention côté équipements et fonctionnalités, beaucoup d'anomalies sont constatées, la facture pour remettre l’ensemble en ordre pourrait être élevée.",
                Bad: "Attention côté équipements et fonctionnalités, beaucoup d'anomalies sont constatées, la facture pour remettre l’ensemble en ordre pourrait être élevée.",
              },
              conclusion:
                "Pour plus de détails, je vous invite à parcourir l'intégralité du rapport ou vous pourrez y trouver mes notes et photos explicatives.",
            },
            recommendation: {
              title: 'Recommandations',
              maintenance: 'Entretien',
              replacement: 'Remplacement',
              repair: 'Réparation',
              aestheticTreatment: 'Traitement esthétique',
              levelAdjustment: 'Ajustement du niveau',
              specializedDiagnosis:
                'Diagnostic en atelier requis pour déterminer la source de l’anomalie.',
              instantDiagnosis: 'Diagnostic instantané',
              wiperReplacement: 'Remplacement des essuie glaces',
              replacementVariableCost: 'Remplacement requis, coût variable',
              batteryPoleMaintenance: 'Entretien pôles de batterie',
              batteryReplacement: 'Remplacement de batterie',
              exhaustPlateFix: "Fixer tôle d'échappement",
              exhaustSystemRepair: "Réparation système d'échappement",
              powerSteeringFluidReplacement:
                'Une fuite du système est présente ou a été présente et le niveau a mal été réalisé. Requiert intervention et vidange du liquide.',
              powerSteeringFluidDrainage:
                'Vidange du système servodirection conseillé',
              coolantSelfAdjusting:
                'Le système s’ajuste de lui même, aucune intervention',
              accessoryBeltReplacement: "Remplacement de courroie d'accessoire",
              wiperFluidFilling: 'Remplissage requis',
              brakeFluidReplacement:
                'Remplacement du liquide hydraulique du système de freinage',
              brakeFluidIntervention:
                'Intervention requise. Véhicule non sécuritaire. Suspicion de fuite du système et/ou erreur humaine de manutention.',
              brakeFluidOk:
                'Aucune intervention requise sur le liquide de frein. Le niveau est en lien avec l’état général des freins aux 4 roues.',
              oilFilterChange: 'Changement d’huile et du filtre à huile',
              toFix: 'À corriger',
              anomalyRange:
                'Diagnostic en atelier requis pour déterminer la source de l’anomalie. Les frais inscrits représentent uniquement le coût moyen d’un diagnostic. Les frais de réparation peuvent varier de {{ minCost }}$ à {{ maxCost }}$.',
              airFilterReplacement: 'Remplacement du filtre à air',
              interiorShampoo: 'Shampoing intérieur complet',
              chargerReplacement: 'Remplacement du chargeur',
              cargoCoverReplacement: 'Remplacement de la plage arrière',
              brakeMaintenance: 'Maintenance des freins',
              brakeReplacement:
                'Remplacement des {{ count }} freins {{ frontBack }} requis',
              brakeService: 'Service des freins',
              motorManagement:
                'Le problème présent ne peut être simplement identifié et nécessite un diagnostique complet en atelier spécialisé. Les frais indiqués sont représentatifs d’un coût moyen de diagnostique pour un système hybride.',
              regenBraking:
                'Le système est non fonctionnel ou partiellement fonctionnel. Le problème présent ne peut être simplement identifié et nécessite un diagnostique complet en atelier spécialisé. Les frais indiqués sont représentatifs d’un coût moyen de diagnostique.',
              leakDiagnostic:
                'Diagnostic en atelier requis avant de réaliser une intervention sur la fuite. Les frais inscrits représentent uniquement le coût moyen d’un diagnostic et non les frais de réparation.',
              leakShampooAndDiagnostic:
                "Un nettoyage de la zone est nécessaire afin de discerner l'origine de la fuite. Ensuite, un diagnostic en atelier est requis avant de réaliser une intervention sur la fuite. Les frais inscrits représentent uniquement le coût moyen d’un shampoing et d'un diagnostic et non les frais de réparation.",
              shockAbsorbersLeak:
                'Les amortisseurs {{ frontBack }} doivent être remplacés. Les frais indiqués sont représentatifs du coût moyen d’un remplacement d’amortisseur, le montant peut varier.',
            },
            connectorType: {
              SAEComboCCS: 'SAE Combo CCS',
            },
          },
          tab_details: {
            downloadAsPdf: 'Télécharger en PDF',
            downloadStarted: 'Téléchargement en cours',
            goodStart: 'Bon démarrage du moteur',
            not_verified_comment: 'Commentaire (élément(s) non vérifié(s)):',
            not_feasible_comment: 'Essai routier non réalisé en raison de : ',
            exterior: 'Extérieur',
            road_test: 'ESSAI ROUTIER',
            details: 'DÉTAILS',
            elements: 'Éléments',
            tires: 'Pneus',
            comment: 'Commentaires',
            not_verified: 'Non vérifié(e)',
            linear_acceleration: 'Accélération constante',
            motorIdle: 'Ralenti du moteur',
            temperature: 'Montée en température et en pression',
            driving_line: 'Tenue de route',
            function_direction: 'Direction',
            easy_shifting: 'Passages de vitesses',
            clutch_grip: "Grip de l'embrayage",
            loudTransmission: 'Bruit normal de la transmission',
            loudDifferential: 'Bruit normal du différentiel',
            running_gear: 'Suspension',
            motor_vibration: 'Bruit moteur',
            brake_noises: 'Freinage ',
            esthetic: 'ESTHÉTIQUE',
            equipment: 'Équipements',
            keys: 'Nombre de clés incluses: ',
            manual: 'Manuel inclus: ',
            floor_mat: 'Tapis de sol inclus: ',
            floor_mat_summer: "État tapis d'été: ",
            floor_mat_winter: "État des tapis d'hiver: ",
            trunk_equipment: 'Équipement(s) présent(s): ',
            additional_equipment: 'Équipement(s) supplémentaire(s) inclus: ',
            interior: 'Intérieur',
            cleanliness: 'Propreté générale',
            odor: 'Odeur',
            Few: 'Peu',
            Many: 'Beaucoup',
            stains: 'de taches/déchirures',
            roof_stains: 'Ciel de toit',
            seat_stains_front: 'Sièges avant',
            seat_stains_back: 'Banquette arrière',
            trunk_stains: 'Coffre',
            cargoCover: 'Cache bagage',
            trunk_rust: 'de rouille dans le fond du coffre',
            floor: 'Plancher',
            bodywork: 'Carosserie',
            bumper: 'Pare-chocs',
            windshieldwiper: 'Essuie-glaces',
            optical_block: 'Blocs optiques',
            mirror: 'Miroirs',
            wing: 'Ailes',
            door: 'Portes',
            lower_body: 'Bas de caisses',
            rim: 'Jantes',
            hood: 'Capot',
            trunk: 'Coffre',
            roof: 'Toit/capote',
            roof_opening: 'Ouverture toit/capote',
            functional: 'FONCTIONNEL',
            safety_belt: 'Ceintures de sécurité',
            front_seat_adjustment: 'Réglages des sièges avant',
            audio_system: 'Système audio',
            dashboard: 'Ordinateur de bord',
            front_windshield_wiper: 'Essuie-glaces avant',
            rear_windshield_wiper: 'Essuie-glaces arrière',
            air_conditioning: 'Climatisation',
            electric_windows: 'Vitres électriques',
            driver_mirror: 'Miroir conducteur',
            interior_mirror: 'Miroir intérieur',
            passenger_mirror: 'Miroir passager',
            glove_box: 'Boite à gants',
            trunk_opening: 'Ouverture du coffre',
            additional_options: 'Option(s) supplémentaire(s): ',
            lights: 'Lumières',
            front: 'Avant',
            rear: 'Arrière',
            front_passing_lamps: 'Ampoule de position',
            front_main_beam: 'Ampoules de croisement',
            front_head_lights: 'Plein-phares',
            fog_lights: 'Antibrouillard',
            turn_signal: 'Clignotants',
            interior_light: 'Éclairages intérieur',
            position_lights: 'Ampoules de position',
            brake_lights: 'Ampoules de freinage',
            reversing_lights: 'Ampoules de marche arrière',
            license_plate_lights: "Ampoules de la plaque d'immatriculation",
            windshield: 'Pare-brise',
            backwindshield: 'Lunette arrière',
            front_driver: 'Avant conducteur: ',
            rear_driver: 'Arrière conducteur: ',
            front_passenger: 'Avant passager: ',
            rear_passenger: 'Arrière passager: ',
            door_opening: 'Ouverture des portes',
            brake: 'Système de Freinage',
            tire_wear: 'Usure',
            engine: 'MÉCANIQUE',
            obd_scanner: 'Témoins lumineux',
            accesory_belt: "Courroie d'accessoire",
            battery: 'Etat de la batterie 12V',
            air_filters: 'État du filtre à air',
            windshield_washer_fluid: 'Niveau du liquide lave-glaces',
            brake_fluid: 'Niveau du liquide de freinage',
            powerSteering_fluid: 'Niveau du liquide de servodirection',
            coolant_fluid: 'Niveau du liquide de refroidissement',
            engine_oil_level: "Niveau d'huile moteur",
            front_driver_side: 'Avant côté conducteur',
            front_passenger_side: 'Avant côté passager',
            rear_driver_side: 'Arrière côté conducteur',
            interior_rear_driver_side: 'Arrière intérieur côté conducteur',
            rear_passenger_side: 'Arrière côté passager',
            interior_rear_passenger_side: 'Arrière intérieur côté passager',
            used: 'Usé, encore bon pour 1-2 saisons',
            change: 'À changer',
            new: 'Neuf',
            none: 'Aucun',
            windshieldwiper_functional: 'Essuie-glaces',
            mirror_functional: 'Miroirs',
            wheels: 'Roues',
            remote_opening: 'Ouverture à distance: ',
            plastics_trim: 'Plastiques et garnitures',
            doors_plastics: 'Plastiques et garnitures des portières',
            dashboard_plastics: 'Tableau de bord',
            central_console_plastics: 'Console centrale',
            horn: 'Klaxon',
            door_locking: 'Verrouillage des portes',
            fuel_door: 'Trappe à essence',
            charging_cable: 'Câble de rechage',
            usbPort: 'Prise(s) USB',
            Plug12v: 'Prise(s) 12V',
            visible_leak: 'Absence de fuite visible',
            visibleEngineLeak: 'Absence de fuite visible du moteur',
            visibleTransmissionLeak:
              'Absence de fuite visible de la transmission',
            visibleDifferentialLeak: 'Absence de fuite visible du différentiel',
            visibleShockAbsorbersLeak:
              'Absence de fuite au niveau des amortisseurs',
            brakeSystemLeak:
              'Absence de fuite au niveau du système de freinage',
            radiatorHose: 'Durites du radiateur',
            radiator: 'Radiateur de refroidissement',
            chargingSystem: 'Système de charge',
            abnormalSmoke: 'Absence de fumée anormale',
            handBrake: 'Frein à main',
            cruiseControl: 'Régulateur de vitesse',
            sunVisor: 'Pare-soleil',
            steeringWheelAdjustment: 'Ajustement du volant',
            paint: 'Peinture de la carrosserie',
            carpets: 'Moquettes',
            instrument_cluster: 'Compteurs/cluster d’instruments',
            maintenance_invoices: "Facture(s) d'entretien incluse(s):",
            four_wheel_drive: '4 roues motrices',
            exhaust_system: "Système d'échappement",
            rust_inhibitor: "Présence d'antirouille: ",
            noisy_accessory_belt:
              'Courroie bruyante après le démarrage de la voiture',
            vehicle_tires: 'Pneus sur le véhicule :',
            tires_2set: '2e set de pneus :',
            pre_mounted_tires: 'Pneus déjà montés (2e set) : ',
            tire1: '1er pneu :',
            tire2: '2e pneu :',
            tire3: '3e pneu :',
            tire4: '4e pneu :',
            tire5: '5e pneu :',
            tire6: '6e pneu :',
            home_charger_working: 'Chargeur 120V fonctionnel',
            home_charger: 'Dommages esthétiques du chargeur 120 Volts',
            electrical_connector: 'Connecteur électrique',
            charge_door: 'Porte de charge',
            'Stone chip': 'Éclat(s)',
            electricSystem: 'Système électrique',
            motorManagement: 'Gestion des moteurs',
            regenBraking: 'Freinage régénératif',
            maxBatteryCapacity: 'Capacité maximale de la batterie',
            heatingSystem: 'Système de chauffage',
          },
          tab_photos: {
            general: 'Général',
            defects: 'Défauts',
          },
        },
        iAInspectionDetails: {
          synthesis: {
            report: 'Compte-rendu',
            carProblem: 'Composants à vérifier',
            isVehicleModified: 'Véhicule modifié',
            commercialUsage: 'Utilisé à des fins commerciales',
            accidentProof: 'Véhicule accidenté',
            isRegistrationCardAvailable: "Carte d'enregistrement disponible",
            carProblemComment: 'Commentaire',
            odometer : 'Odomètre',
          },
          photosTab: {
            principal: 'Photos principales',
            additional: 'Photos additionnelles',
            photo1: 'Première photo',
            photo2: 'Deuxième photo',
            photo3: 'Troisième photo',
            photo4: 'Quatrième photo',
            photo5: 'Cinquième photo',
            photo6: 'Sixième photo',
            photo7: 'Septième photo',
            photo8: 'Huitième photo',
            photo9: 'Neuvième photo',
            photo10: 'Dixième photo',
            photo11: 'Onzième photo',
            photo12: 'Douzième photo',
            photo13: 'Treizième photo',
            photo14: 'Quatorzième photo',
            photo15: 'Quinzième photo',
            doorNiv: 'NIV sur la porte',
            windshieldNiv: 'NIV sur le pare-brise',
            licensePlate: "Plaque d'immatriculation",
            registrationCard: "Carte d'enregistrement",
            vehicleModification: 'Modification du véhicule',
            vehicleModification2: 'Deuxième modification du véhicule',
            vehicleModification3: 'Troisième modification du véhicule',
            vehicleModification4: 'Quatrième modification du véhicule',
            vehicleModification5: 'Cinquième modification du véhicule',
            commercialUsage1: 'Usage commercial',
            commercialUsage2: 'Deuxième usage commercial',
            commercialUsage3: 'Troisième usage commercial',
            accidentProof1: "Preuve d'accident",
            accidentProof2: "Deuxième preuve d'accident",
            accidentProof3: "Troisième preuve d'accident",
            accidentProof4: "Quatrième preuve d'accident",
            accidentProof5: "Cinquième preuve d'accident",
          },
        },
      },
      businessInspection: {
        login: 'Connexion',
        password: 'Mot de passe',
        businessName: 'Nom',
        businessStreet: 'Adresse',
        businessCity: 'Ville',
        businessZipCode: 'Code postal',
        aboutBusiness: "Informations de l'entité qui détient le véhicule",
        comment: 'Informations complémentaires',
        commentSection:
          "Veuillez ajouter toute information complémentaire qui puisse améliorer la qualité de l'inspection",
        delai: "Délais d'inspection",
        validation: 'Validation',
        delai24: '24h',
        delai48: '48h',
        conditions:
          "En cochant cette case, je consens à être facturé aux tarifs entendus dans le contrat et que, l'inspection CarInspect répondra aux termes entendus dans ledit contrat.",
        accept: "J'accepte",
        inspectionType: "Type d'inspection",
        niv: "Numéro d'identification du véhicule NIV",
        PreAchat: 'Inspection préachat',
        inpsectionComplete: 'Inspection standard',
        PoidsLourd: 'Poids Lourd',
        ComposantSpecifique: "inspection d'un composant spécifique",
        Huile: "Test d'huile",
        book: "Réserver l'inspection",
        TroubleShoot: 'Troubleshoot',
        PSI: 'PSI',
        Complete: 'Complete',
        confirmationModal: {
          title: 'Confirmation de la réservation',
          text: 'Votre réservation a été confirmée. Merci de votre confiance.',
        },
        phoneNumber: {
          title: 'Numéro de téléphone',
        },
      },

      // pour le moment en anglais, à traduire lorsque voulu
      psiForm: {
        engineComponent: 'Engine',
        transmissionComponent: 'Transmission',
        driveTrainComponent: 'Powertrain / Drivetrain',
        oilLeaksComponent: 'Oil leaks',
        structureComponent: 'Structure/Frame',
        suspensionComponent: 'Suspension/Subframe',
        catalyticConverterComponent:
          'Catalytic converter(s) / Exhaust manifolds',
        normalWearComponent: 'Normal Wear & Tear',
        warningLightsComponent: 'Warning Lights On',
        codeComponent: 'Equipped with TPMS',
        srsComponent: 'SRS (airbags)',
        acSystemComponent: 'A/C System (not equipped/problem)',
        electricalAccessoriesComponent: 'Electrical accessories',
        testDriveComponent: 'Test Drive',
        inspectionPhotosComponent:
          'Inspection photos (paintwork, defaults, etc.)',
        absComponent: 'ABS',
        testdriveComponent: 'Test drive',
        photoComponent: 'Inspection photos (paintwork, default, etc.) ',
        electricalComponent: 'Electrical accessories',
        acComponent: 'A/C System (not equipped/problem)',
        equippedTPMSComponent: 'Equipped with TPMS',
      },
      purchase: {
        // firstStepTitle: 'Vérifier la disponibilité',
        comment: 'Informations complémentaires',
        commentSection:
          "Veuillez ajouter toute information complémentaire qui puisse améliorer la qualité de l'inspection",
        vehicleInformation: 'Informations sur le véhicule',
        make: 'Marque',
        model: 'Modèle',
        year: 'Année',
        mileage: 'Kilométrage',
        outsideZoneCheckbox:
          "Je confirme avoir communiqué avec l'équipe CarInspect pour vérifier la possibilité de réaliser une inspection dans cette zone.",
        carfaxUrl: 'Carfax',
        VIN: 'NIV',
        formFilled: 'Ce formulaire a déjà été rempli. Merci!',
        invalidPurchaseID:
          "Il s'agit d'un identifiant d'achat invalide. Veuillez nous contacter à info@carInspect.com",
        sellerTypes: {
          title: 'Type de vendeur',
          dealer: 'Concessionnaire',
          private: 'Particulier',
          owner: 'Je suis le propriétaire de la voiture',
        },
        processRequest: 'Avez vous bien compris tout le processus proposé?',
        verifyAvailability:
          'Dans quelle ville est situé le véhicule à inspecter ?',
        address: 'Adresse du vendeur',
        sellerCoordinates: 'Coordonnées du vendeur',
        phoneNumber: 'Numéro de téléphone',
        sellerPhoneNumber: 'Numéro de téléphone du vendeur',
        sellerName: 'Nom du vendeur',
        city: 'Ville du vendeur',
        dealerName: 'Nom du concessionnaire',
        adUrl: "URL de l'annonce",
        zipCode: 'Code postal du vendeur',
        zipCodeUser: 'Code postal',
        price: 'Prix affiché',
        buyerInfo: 'Coordonnées personnelles',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Adresse courriel',
        confirmEmail: 'Confirmer le courriel',
        callAfterInspection:
          'L’inspecteur vous appellera pour un bilan de l’état de la voiture et répondra à vos questions (Gratuit)',
        callAfterInspectionTitle: 'Appel téléphonique post-inspection',
        notes: 'Notes supplémentaires',
        onfirmEmail: 'Confirmer le courriel',
        submit: 'Soumettre',
        terms: 'En appuyant sur le bouton continuer ci-dessous, ',
        invalidEmail: 'Les adresses e-mail ne correspondent pas.',
        upsaleForm: 'Mon inspection CarInspect',
        reviewCart: 'Revoir panier',
        totalPrice: 'Pour un prix total de ',
        selected: 'Vous avez selectionné une ',
        for: 'pour une',
        options: 'Avec les options suivantes  :',
        upsaleMarketValue:
          'Obtenez une analyse de marché pour vous aider à faire une bonne affaire.',
        upsalePhoto:
          'Obtenez un rapport photo détaillé des défauts esthétiques.',
        bookInspection: 'Réserver mon inspection',
        payment: 'Paiement',
        bookingConfirmation:
          "Félicitations, ta demande d'inspection est validée. Il ne te reste plus qu'à la confirmer en passant au paiement.",
        pay: 'Payer',
        confirmPayment:
          "J'accepte d'être contacté pour obtenir le prix négocié pour une assurance auto ; sans engagement & sans frais.",
        back: 'Retour',
        next: 'Continuer',
        backToWebsite: 'Retour au site web',
        summary: "Récapitulatif de l'achat : ",
        invalidZipCodeFormat:
          "Code postal invalide, veuillez vérifier l'adresse.",
        availableZone:
          "Nous sommes disponibles pour inspecter votre véhicule sous 24h ou, si ce n'est pas possible dans les 48h. Après la réservation, CarInspect fixe l'heure avec le vendeur. Nous nous occupons de tout.",
        unavailableZone:
          "Merci de contacter l'équipe CarInspect pour vérifier les disponibilités dans cette zone.",
        questions:
          'Vous avez encore des questions ? |contactez-nous|, il nous fera plaisir de vous y répondre.',

        exoticCar: 'Veuillez nous |contacter',
        leadOrigin: {
          title:
            "Comment avez-vous entendu parler de CarInspect? (l'équipe marketing vous remercie pour votre aide 🤗)",
          placeholder: 'Choisir une option',
          search: "Recherche sur l'Internet",
          marketing: 'Publicité sur Facebook ou Instagram',
          kijiji: 'Kijiji Autos',
          media: 'Dans la presse, radio, etc.',
          blog: 'Article de blog',
          mouth: 'Bouche à oreille',
          other: {
            title: 'Autre',
            placeholder: 'Précisez',
          },
          otherRequest: 'Veuillez préciser',
          preferNotToAnswer: 'Je préfère ne pas répondre',
          socialNetworks: 'Réseaux sociaux',
        },
        upsale: {
          marketValue: 'Analyse de marché',
          image: 'Rapport photo détaillé',
        },
        carfax: {
          title: 'URL du rapport Carfax',
          subText:
            "Optionnel. Notre inspecteur peut vérifier l'historique via le rapport CARFAX. Vous pouvez |acheter le CARFAX en cliquant ICI|.",
        },
        inspectionCreditCode: {
          title: "Code de crédit d'inspection",
          redeem: 'Utiliser le crédit',
          error: {
            title: 'Ce code ',
            'Not found': "n'existe pas",
            'Already used': 'a déjà été utilisé',
          },
          confirmation: {
            title: 'Votre inspection est confirmée',
            message: 'Vous recevrez une confirmation par courriel sous peu.',
          },
        },
        clientOwner: 'La voiture est celle du client.',
      },
      inspectionPage: {
        B2BTitle: "Inspection d'entreprise",
        completeTitle: 'Inspection complète',
        iATitle: "Inspection d'assurance",
        exactInfo: 'Est-ce que les informations sont exactes?',
        validate: 'Valider',
        backToForm: 'Retour au formulaire',
        reportProblem: 'Signaler un problème',
        problemPlaceholder: 'Précisez le problème',
        requestCorrection: 'Envoyer le problème',
        correctionDelayWarning:
          "Le rapport a été envoyé en correction. Un délai de 2h peut être requis avant qu'il soit envoyé au client.",
        completedInspectionModal: {
          title: 'Inspection terminée',
          message: 'Fermer et soumettre cette inspection?',
          buttonText: 'Oui, je confirme',
        },
      },
    },
    sequence: {
      rating: {
        question: 'Ajouter ce modèle dans ma liste de voitures favorites ? ',
      },
    },
    homepage: {
      logo_subtitle:
        'Ton partenaire de confiance pour acheter ta voiture usagée',
      tiles: {
        title: 'Bienvenue',
        search: {
          title: 'Recherche',
          description: 'Sélectionne tes modèles favoris',
        },
        profile: {
          personal_information: 'Information personelles',
          research_criteria: 'Critères',
          preferences: 'Préfèrences',
          save: 'Sauvegarder',
          my_profile: 'Mon profil',

          first_tab: {
            first_name: 'Prénom',
            last_name: 'Nom',
            email: 'Courriel',
          },

          second_tab: {
            search_radius: 'Rayon de recherche',
            type_of_occasion: "Type d'occasion",
            go_study: 'Aller étudier',
            go_work: 'Aller travailler',
            travel: 'Voyager',
            professional_use: 'Usage Professionel(Eva,Uber,Etc.)',
            drifting: 'Déraper sur le parking du Maxi',
            other: 'Autre',
            fuel: 'Carburant',
            electric: 'Électrique',
            hybrid: 'Hybride(électrique et essence)',
            gasoline: 'Essence',
            no_preference: 'Pas de préférence',
            number_of_passenger: 'Nombre de passager',
          },
        },
        ads: {
          title: 'Mes annonces',
          description: 'Consulte tes annonces',
        },
        criterias: {
          title: 'Mes critères',
          description: 'Modifie à tout moment tes critères de recherche',
        },
        insurance: {
          title: 'Assurance',
          description: 'Compare les assurances et trouve la moins chère',
        },
        ressources: {
          title: 'Ressources',
          description: "Des guides pour t'aider à chaque étape",
        },
        inspection: {
          title: 'Inspection',
          description: 'Assure-toi du bon état de ta future voiture',
        },
      },
    },
  },
  sequence: {
    sequence: 'En cours',
    home: 'Tableau de bord',
    help: "J'ai besoin d'aide",
    completed: 'complété',
    vehicle_suggestion:
      'Tu dois avoir donné une note à chacun des véhicules avant de continuer 😉',
  },
  admin: {
    menu: {
      general: 'Général',
      ads: 'Annonces',
      guides: 'Guides',
      users: 'Usagers',
      sequences: 'Séquences',
      settings: 'Paramètres',
      vehicles: 'Véhicules',
      todo: "Liste 'À faire'",
      ressources: 'Ressources',
    },
    general: {
      'waiting-list': {
        title: {
          waiting: 'En attente de suggestions',
          'waiting/ads': "En attente d'annonces",
          'waiting/purchase': 'En achat',
          inprogress: 'En cours',
        },
      },
      'total-users': 'Clients enregistrés',
    },
    sequence: {
      title: 'Gestion des séquences',
      delete: 'Êtes-vous certain de vouloir supprimer cette séquence?',
      new: 'Nouvelle séquence',
      show_done_button: "Montrer le bouton 'Suivant'",
      notify_admins: 'Envoyer une notification aux administrateurs',
      waiting_on_expert_label:
        "Texte pour l'attente envers un expert CarInspect",
      step: {
        'vehicle-suggestions': 'Suggestion de véhicules',
        text: 'Texte',
        payment: 'Paiement',
      },
      hasEndModal: 'Montrer le popup final',
      hasInitialModal: 'Montrer le popup initial',
    },
    vehicles: {
      title: 'Véhicules',
      makes: 'Marques',
      types: 'Types',
      years: 'Années',
      modal: {
        title: 'Modification du véhicule',
        infos: 'INFOS',
        form: 'FICHE',
        make: 'Marque',
        model: 'Modèle',
        type: 'Type',
        drivetrain: 'Motricité',
        fuel: 'Carburant',
        consumption: 'Consommation',
        maintenance: 'Entretiens',
        origin: 'Origine',
        warranty: 'Garantie',
        resell_value: 'Valeur de revente',
        category: 'Catégorie',
        advantages: 'Avantages',
        disadvantages: 'Inconvénients',
        image: 'Image',
        thumbnail: 'Thumbnail',
        images: 'Images',
        selection: 'Sélection',
        search: 'Recherche',
        reliability: 'Fiabilité',
        money_value: 'Rapport qualité prix',
        money_value_short: 'Prix',
        comfort: 'Confort',
        fun_factor: 'Plaisir de conduite',
        fun_factor_short: 'Plaisir',
        security: 'Securité',
        cargo_space: 'Espace de chargement',
        recalls: 'Rappels',
        recommended_drivetrain: 'Motorisation conseillée',
        year_min: 'Année (min)',
        year_max: 'Année (max)',
      },
    },
    users: {
      title: 'Usagers',
      display_name: 'Nom',
      email: 'Courriel',
      progression: 'Progression',
      creation: 'Inscription',
      lastSignInTime: 'Dernière connexion',
      modal: {
        title: "Information de l'usager",
        step: 'Étape',
        completed_true: 'Terminée',
        completed_false: 'En cours',
        suggest_vehicles: 'Suggérer des véhicules',
        suggestions: {
          title: 'Suggestion de véhicules',
        },
        close_entry: "Fermer l'entrée",
        close_entry_question:
          'Êtes-vous certain de vouloir fermer cette entrée?',
        reset_entry: "Réinitialiser l'entrée",
        reset_entry_question:
          'Êtes-vous certain de vouloir réinitialiser cette entrée?',
        skip_step: "Passer l'étape",
        reset_step: "Réinitialiser l'étape",
        skip_step_question:
          'Êtes-vous certain de vouloir faire passer cette étape à cet utilisateur?',
        reset_step_question:
          "Êtes-vous certain de vouloir réinitialiser l'étape de cet utilisateur?",
        no_entries: "Aucune entrée n'a été trouvée",
        no_ads: "Aucune annonce n'a été assignée",
        no_vehicle_selected: 'Aucun véhicule sélectionné',
        has_paid: 'A payé pour le service',
        has_bought: 'A acheté sa voiture',
      },
      search: {
        title: 'Recherche',
      },
      tabs: {
        sequences: 'Séquences',
        ads: 'Annonces',
      },
      ads: {
        modal: {
          title: 'Annonce',
          site: 'Site',
          url: 'Url',
          description: 'Description',
          valid: 'Cette annonce est valide.',
          invalid: 'Cette annonce est invalide.',
          price: 'Prix',
          thumbnail: "Image mise de l'avant",
          year: 'Année',
          version: 'Version',
          mileage: 'Kilométrage',
          published: 'Publiée',
          buying_budget: "Budget d'achat",
          taxes: 'Taxes',
          warranty: 'Garanties',
          yearly_budget: 'Budget annuel',
          licenses: 'Permis et Immatriculation',
          maintenance: 'Maintenance',
          fuel: 'Essence',
          insurance: 'Assurance',
          tax_dealer: 'TPS + TVQ',
          tax_person: 'TPS ',
          receive_announcement: 'Recevoir des annonces',
          research_announcement:
            'La recherche est basée sur tes modèles favoris et les critères ajoutés dans la page Mes critères.',
          ready_to_receive:
            'Nous avons besoin de quelques informations supplémentaires afin de lancer la recherche. Remplis les champs suivants.',
        },
      },
    },
    ads: {
      title: 'Annonces',
      creation: 'Création',
    },
    settings: {
      title: 'Paramètres',
      default_sequence: 'Séquence par défaut',
      vehicle_update_button: 'LeParking - Mise à jour des véhicules',
      vehicle_update_message:
        'Êtes-vous certain de vouloir activer la mise à jour? Cette action est TRÈS coûteuse. (temps + $)',
      vehicle_update_confirmation: 'Êtes-vous certain?',
      vehicle_update_confirmation_second:
        'Une autre fois pour la forme. Êtes-vous certain certain?',
    },
    guides: {
      title: 'Guides',
      modal: {
        title: 'Modification du guide',
        name: 'Nom',
        description: 'Description',
        enabled: 'Montrer aux utilisateurs',
        link: 'Lien vers le site web',
      },
    },
  },
  dashboard: {
    favorites: 'Ma sélection',
    create_own_favorite: 'À toi de créer ta liste de modèles préférés !',
    search_specific_model:
      'Recherche des véhicules spécifiques, supprime ou ajoute des modèles !',
    search_best:
      'Une fois ta liste de 3 modèles maximum choisis, clique sur "Rechercher les meilleures annonces",',
    find_the_best_market:
      'pour que CarInspect aille dénicher les meilleures affaires sur le marché !',
    search_best_button: 'Rechercher les meilleures annonces',
    no_favorite: "Tu n'as aucun favori, choisis-en et lance ta recherche!",
    vehicle_suggestion:
      'Tu ne sais pas quels véhicules sont faits pour toi? Découvre-les en utilisant notre',
    recommendation_algorithm: 'algorithme de recommendation',
    next_search: 'Ta prochaine recherche pourra être effectuée dans : ',
    hour: 'heure',
    searchPlaceholder: 'Rechercher un véhicule',
    completedSelection: {
      title: 'Ta liste est pleine!',
      content: "Tu peux maintenant lancer la recherche d'annonces.",
      tooManyCars:
        'Tu ne peux ajouter que 3 modèles à ta sélection. Modifie-la ou lance la recherche.',
    },
    email: {
      title: 'Entre ton adresse courriel',
      invalid: 'Adresse courriel invalide',
    },
    zipCode: {
      title: 'Entre ton code postal',
      invalid: 'Code postal non valide',
    },
    gearbox: {
      title: 'Boîte de vitesses',
      none: 'Aucune préférence',
      automatic: 'Automatique',
      manual: 'Manuelle',
    },
    fuelType: {
      title: "Type d'essence",
      none: 'Aucune préférence',
      essence: 'Essence',
      gas: 'Essence',
      diesel: 'Diesel',
      hybride: 'Hybride',
      hybrid: 'Hybride',
      'hybride branchable': 'Hybride branchable',
      'plug-in hybrid': 'Hybride branchable',
      electrique: 'Électrique',
      electric: 'Électrique',
      ethanol: 'Ethanol',
    },
    distance: {
      title: 'Rayon de recherche',
    },
    minYear: {
      title: 'Année minimale',
    },
    maxMileage: {
      title: 'Kilométrage maximal',
    },
    conditions:
      "J'accepte les |conditions générales| et les |politiques de confidentialité| de CarInspect.",
    launchSearch: 'Lancer la recherche',
    validation: {
      title: 'Félicitations!',
      body: 'Tu recevras les meilleures annonces sous peu par courriel.\n\nPsst : Il se peut que le courriel arrive dans les promotions ou les SPAMS.',
    },
  },
  stripe: {
    method: 'Méthodes de paiement',
    thank_you: 'Merci',
    validate_inspection:
      "Dès qu'un inspecteur de notre réseau valide l'inspection, on te revient avec la date et l'heure de celle-ci.",
    amount_to_pay: 'Montant payé :',
    left_to_pay: 'Reste à payer :',
    the: 'le',
    summary_payment: "Résumé de l'achat : ",
    payment_made: 'Paiement effectué!',

    success: {
      title: "Nous confirmons votre réservation d'inspection. Merci ! ",
      subtitle: 'Vous recevrez un courriel de confirmation bientôt.',
      stepsTitle: 'Voici les prochaines étapes.',
      stepsSubtitle:
        "Vous serez tenu informé.e par courriel pour chacune d'entre elles.",
      stepAfterReservation:
        'Pour finaliser votre réservation, veuillez compléter les informations sur le véhicule en cliquant ci-dessous. Vous pouvez aussi le faire plus tard (vous recevrez le lien par courriel).',
      step1: 'Un inspecteur de notre réseau prend en charge votre inspection.',
      step2:
        "L'inspecteur contacte le vendeur pour fixer une date et une heure pour l'inspection.",
      step3: "L'inspection est réalisée par l'inspecteur.",
      step4:
        "L'inspecteur vous contacte par téléphone si demandé et vous recevez un rapport détaillé par courriel.",
    },
    failure: {
      title:
        "Nous n'avons pas pu procéder au paiement. Votre inspection est en attente.",
    },
    return: 'Retour au site web',
  },
  inspection: {
    fields: {
      goodStart: 'Bon démarrage du moteur',
      loudTransmission: 'Transmission bruyante',
      loudDifferential: 'Différentiel bruyant',
      constantAccelerationRoadTest:
        'Montée en régime normale à chaud avec une accélération constante et linéaire',
      normalPressureTempRoadTest:
        'Montée en température et en pression normale du moteur',
      goodLineKeeping:
        'Bonne tenue de la ligne de conduite (lors des accélérations et freinages)',
      directionProperFunctioning:
        'Bon fonctionnement de la direction (absence de jeu et de vibrations)',
      smoothGearShift:
        "Passage de vitesses aisés et absence d'accros au niveau des synchros de boite",
      goodClutch:
        "Bon grip de l'embrayage ou absence de patinage excessif du convertisseur de couple",
      goodSuspension:
        "Absence d'anomalie au niveau des trains roulants (suspensions, amortisseurs, rotules, etc.)",
      frontSuspension:
        "Absence d'anomalie au niveau du train roulant avant (suspension, amortisseurs, rotules, etc.)",
      rearSuspension:
        "Absence d'anomalie au niveau du train roulant arriere (suspension, amortisseurs, rotules, etc.)",
      engineVibration: 'Absence de vibrations moteurs anormales',
      brakeNoice:
        'Absence de vibrations et de bruits anormaux lors du freinage',
      summerFloorMats: "État tapis d'été",
      winterFlootMats: "État du tapis d'hiver",
      overallCleanliness: "La propreté globale de l'intérieur",
      headliner: 'Taches au ciel de toit',
      frontSeats: 'Taches aux sièges avant',
      backSeats: 'Taches à la banquette arrière ',
      insideTrunk: 'Taches ou des débris dans le coffre',
      trunkLiner: 'Des marques de rouille aux fond de coffre',
      floor: 'État du plancher',
      thicknessPaint: 'Épaisseur de la peinture',
      frontBumper: 'État du parechoc avant',
      backBumper: 'État du parechoc arrière',
      frontWindshieldWiperEsthetic: "État des bras d'essuie-glaces avant",
      backWindshieldWiperEsthetic: "État du bras d'essuie-glace arrière",
      driverFrontOpticalBlock: 'État du bloc optique avant côté conducteur',
      cargoCover: 'Cache bagage',
      driverBackOpticalBlock: 'État du bloc optique arrière côté conducteur',
      passengerFrontOpticalBlock: 'État du bloc optique avant côté passager',
      passengerBackOpticalBlock: 'État du bloc optique arrière côté passager',
      driverMirrorEsthetic: 'État du miroir avant côté conducteur',
      passengerMirrorEsthetic: 'État du miroir avant côté passager',
      driverBackWing: "État de l'aile arrière côté conducteur",
      driverFrontWing: "État de l'aile avant côté conducteur",
      passengerBackWing: "État de l'aile arrière côté passager",
      passengerFrontWing: "État de l'aile avant côté passager",
      driverFrontDoor: 'État de la portière avant côté conducteur',
      driverBackDoor: 'État de la portière arrière côté conducteur',
      passengerFrontDoor: 'État de la portière avant côté passager',
      passengerBackDoor: 'État de la portière arrière côté passager',
      driverUnderBody: 'État du bas de caisse côté conducteur',
      passengerUnderBody: 'État du bas de caisse côté passager',
      driverFrontRim: 'État de la jante avant côté conducteur',
      driverBackRim: 'État de la jante arrière côté conducteur',
      passengerFrontRim: 'État de la jante avant côté passager',
      passengerBackRim: 'État de la jante arrière côté passager',
      hood: 'État du capot',
      trunk: 'État du coffre',
      roof: 'État du toit',
      odor: 'Odeur',
      safetyBelt: 'Ceintures de sécurité',
      frontSeatsSettings: 'Réglages des sièges avant',
      audioSystem: 'Système audio et les hauts parleurs',
      onboardComputer: 'Ordinateur de bord',
      frontWindshieldWiper: 'Essuie-glaces avant',
      backWindshieldWiper: 'Essuie-glaces arrière',
      airConditioning: 'Climatisation',
      airFilter: 'Filtre à air',
      electricWindows: 'Vitres électriques',
      driverMirror: 'Miroir côté conducteur',
      interiorMirror: 'Miroir intérieur',
      passengerMirror: 'Miroir côté passager',
      gloveCompartment: 'Boîte à gants',
      trunkOpening: 'Ouverture du coffre',
      frontWindshield: 'État du pare-brise',
      backWindshield: 'État de la lunette arrière',
      driverBackDoorOpening: 'Ouverture de la portière arrière côté conducteur',
      driverFrontDoorOpening: 'Ouverture de la portière avant côté conducteur',
      passengerFrontDoorOpening: 'Ouverture de la portière avant côté passager',
      passengerBackDoorOpening:
        'Ouverture de la portière arrière côté passager',
      driverBackBrake: 'État des freins arrière ',

      driverFrontBrake: 'État des freins avant ',
      passengerFrontBrake: 'État des freins avant ',
      passengerBackBrake: 'État des freins arrière ',
      frontPassingLamps: 'Lumières de croisement',
      frontMainBeam: 'Lumières de route avant',
      frontHeadLights: 'Plein-phares',
      frontFogLights: 'Lumières antibrouillard',
      frontFlashingSignalLamps: 'Clignotants avant',
      insideLighting: "Éclairages d'intérieur",
      backPositionLamps: 'Lumières de position arrière',
      brakeLights: 'Lumières de freinage',
      reversingLamps: 'Lumières de marche arrière',
      licensePlateLight: "Éclairage de la plaque d'immatriculation",
      backFlashingSignalLamps: 'Clignotants arrière',
      accessoryBelt: "État de la courroie de d'accessoire",
      wiperFluidLevel: 'Niveau du liquide lave-glaces',
      brakeFluidLevel: 'Niveau du liquide de freins',
      motorOilLevel: "Niveau d'huile moteur",
      motorOilQuality: "Qualité d'huile moteur",
      driverShockAbsorber: "État de l'amortisseur avant coté conducteur",
      passengerShockAbsorber: "État de l'amortisseur avant coté passager",
      driverFrontTirePressure: 'Pression du pneu avant côté conducteur',
      passengerFrontTirePressure: 'Pression du pneu avant côté passager',
      driverBackTirePressure: 'Pression du pneu arrière côté conducteur',
      passengerBackTirePressure: 'Pression du pneu arrière côté passager',
      driverFrontTireWear: 'Pneu avant côté conducteur',
      driverBackTireWear: 'Pneu arrière côté conducteur',
      passengerFrontTireWear: 'Pneu avant côté passager',
      passengerBackTireWear: 'Pneu arrière côté passager',
      isOBDScanner: 'Témoins lumineux ',
      driverFrontTire: 'Pneu avant côté conducteur',
      driverBackTire: 'Pneu arrière côté conducteur',
      interiorDriverBackTireWear: 'Pneu arrière intérieur côté conducteur',
      passengerFrontTire: 'Pneu avant côté passager',
      passengerBackTire: 'Pneu arrière côté passager',
      interiorPassengerBackTireWear: 'Pneu arrière intérieur côté passager',
      carPlastics: 'Plastiques et garnitures',
      coolantLevel: 'Niveau du liquide de refroidissement',
      Extra: 'Photo',
      Extra2: 'Photo ',
      Extra3: 'Photo ',
      Extra4: 'Photo ',
      doorLocking: 'Verrouillage des portes',
      carRemoteOpening: 'Ouverture à distance',
      usbPort: 'Prise(s) USB ',
      fuelDoor: 'Trappe à essence',
      chargingCable: 'Cable de recharge',
      carHorn: 'Klaxon',
      homeCharger: 'Chargeur à domicile',
      electricalConnector: 'Connecteur électrique',
      instrumentCluster: 'Compteurs/cluster d’instruments',
      Plug12v: 'Prise(s) 12V',
      visibleEngineLeak: 'Fuite moteur',
      visibleTransmissionLeak: 'Fuite transmission',
      visibleDifferentialLeak: 'Fuite différentiel(s)',
      brakeSystemLeak: 'Fuite au niveau du système de freinage',
      visibleShockAbsorbersLeak: 'Fuite au niveau des amortisseurs',
      paint: 'Peinture de la carrosserie',
      carpets: 'Moquettes',
      doorsPlastics: 'Plastiques et garnitures des portières',
      doorPlastics: 'Plastiques et garnitures des portières',
      dashboardPlastics: 'Plastiques et garnitures du tableau de bord',
      centralConsolePlastics: 'Plastiques et garnitures de la console centrale',
      powerSteeringFluidLevel: 'Niveau du liquide de servodirection',
      battery: 'Etat de la batterie 12V',
      exhaustSystem: "Système d'échappement",
      wheelAlignment: 'Alignement des roues',
      Front_tires: 'Pneus avant',
      Rear_tires: 'Pneus arrière',
      tires: 'Pneus',
      roofOpening: 'Ouverture du toit/capote',
      driverLiftingPoint: 'Points de levage côté conducteur',
      passengerLiftingPoint: 'Points de levage côté passager',
      passengerBody: 'Bas de caisse (coté passager)',
      cruiseControl: 'Régulateur de vitesse',
      sunVisor: 'Pare-soleil',
      engineLeak: 'Fuite moteur',
      motorManagement: 'Gestion du moteur',
      regenBraking: 'Freinage régénératif',
      heatingSystem: 'Système de chauffage',
    },

    photos: {
      'Avant du véhicule': 'Avant du véhicule',
      'Arrière du véhicule': 'Arrière du véhicule',
      'Trois-quart avant côté conducteur du véhicule':
        'Trois-quart avant côté conducteur du véhicule',
      'Trois-quart avant côté passager du véhicule':
        'Trois-quart avant côté passager du véhicule',
      'Côté conducteur du véhicule': 'Côté conducteur du véhicule',
      'Côté passager du véhicule': 'Côté passager du véhicule',
      'Trois-quart arrière côté conducteur du véhicule':
        'Trois-quart arrière côté conducteur du véhicule',
      'Trois-quart arrière côté passager du véhicule':
        'Trois-quart arrière côté passager du véhicule',
      'Compartiment moteur': 'Compartiment moteur',
      'Compartiment avant': 'Compartiment avant',
      'Compartiment arrière': 'Compartiment arrière',
      'Intérieur du coffre': 'Intérieur du coffre',
      'Caméra de recul': 'Caméra de recul',
      'Système de navigation': 'Système de navigation',
      carFront: 'Avant du véhicule',
      carBack: 'Arrière du véhicule',
      carThreeQuarterFrontDriver:
        'Trois-quart avant côté conducteur du véhicule',
      carThreeQuarterFrontPassenger:
        'Trois-quart avant côté passager du véhicule',
      carSideDriver: 'Côté conducteur du véhicule',
      carSidePassenger: 'Côté passager du véhicule',
      carThreeQuarterBackDriver:
        'Trois-quart arrière côté conducteur du véhicule',
      carThreeQuarterBackPassenger:
        'Trois-quart arrière côté passager du véhicule',
      engineBay: 'Compartiment moteur',
      frontCompartment: 'Compartiment avant',
      backCompartment: 'Compartiment arrière',
      trunkCompartment: 'Intérieur du coffre',
      rearCamera: 'Caméra de recul',
      navigationSystem: 'Système de navigation',
      driverFrontTire: 'Pneu avant côté conducteur',
      driverBackTire: 'Pneu arrière côté conducteur',
      passengerFrontTire: 'Pneu avant côté passager',
      passengerBackTire: 'Pneu arrière côté passager',
      roof: 'Toit du véhicule',
      frontPassengerCompartment: 'Compartiment avant passager',
      backPassengerCompartment: 'Compartiment arrière passager',
      backDriverCompartment: 'Compartiment arrière conducteur',
      frontDriverCompartment: 'Compartiment avant conducteur',
      spareTire: 'Pneu de secours',
      trunkEquipment: 'Équipement du coffre',
      driverFrontLiftingPoint: 'Point de levage avant conducteur',
      passengerBackLiftingPoint: 'Point de levage arrière passager',
      driverBackLiftingPoint: 'Point de levage arrière conducteur',
      passengerFrontLiftingPoint: 'Point de levage avant passager',
      niv: 'Photo du NIV',
      instrumentCluster: 'Compteur',
      Extra: 'Photo supplémentaire',
      Extra2: 'Photo supplémentaire',
      Extra3: 'Photo supplémentaire',
      Extra4: 'Photo supplémentaire',
      tire1: '1er pneu du 2e set',
      saaqSticker: 'Étiquette de la SAAQ',
    },
    comments: {
      Yes: 'Oui',
      Oui: 'Oui',
      No: 'Non',
      Non: 'Non',
      'Oui avec réparations recommandées': 'Oui avec réparations recommandées',
      'Yes, with recommended repairs': 'Oui avec réparations recommandées',
      nothingToPlan: 'Rien à prévoir',
      replacementSoon: 'Remplacement à prévoir prochainement',
      replacementRequired: 'Remplacement à prévoir ',
      replacementRequiredTooOld: 'Remplacement à prévoir (âge maximal atteint)',
      'Non-applicable': 'N/A',
      Mesure_hors_de_la_fourchette: 'Mesure hors de la fourchette',
      suspicion_d_un_accident: "suspicion d'un accident",
      Compris_dans_la_fourchette: 'Compris dans la fourchette',
      aucune_preuve_d_accident: "aucune preuve d'accident",
      'Déchirure(s)': 'Déchirure(s)',
      Excellent: 'Excellent',
      Bon: 'Bon',
      Mauvais: 'Mauvais',
      Fonctionnelle: 'Fonctionnelle',
      'Non-fonctionnel': 'Non-fonctionnel',
      'Non-fonctionnelle': 'Non-fonctionnelle',
      'Non-fonctionnelles': 'Non-fonctionnelles',
      'Non-fonctionnels': 'Non-fonctionnels',
      Fonctionnels: 'Fonctionels',
      Functional: 'Fonctionnel(le)(s)',
      Nonfunctional: 'Non-fonctionnel(le)(s)',
      Fissures: 'Fissures',
      Fissuré: 'Fissuré',
      Fissurée: 'Fissurée',
      Fissurés: 'Fissurés',
      Fissurées: 'Fissurées',
      'Impact(s)': 'Impact(s)',
      Partiellement_opaque: 'Partiellement opaque',
      Opaque: 'Opaque',
      Présence_d_humidité: "Présence d'humidité",
      'Impact(s)_présent(s)': 'Impact(s) présent(s)',
      Très_légèrement_opaque: 'Très légèrement opaque',
      Retouches_peintures: 'Retouches peintures',
      Absence_d_une_visse: 'Absence d’une vis',
      Rouille_de_surface: 'Rouille de surface',
      Bien: 'Bien',
      Peu: 'Peu',
      Aucune_odeur: 'Aucune odeur',
      Excellente: 'Excellente',
      Beaucoup: 'Beaucoup',
      Hiver: 'Hiver',
      Winter: 'Hiver',
      Été: 'Été',
      Summer: 'Été',
      Pneu_de_secours: 'Pneu de secours',
      Kit_de_changement_de_pneu: 'Kit de changement de pneu',
      'Kit_anti-crevaison': 'Kit anti-crevaison',
      "Crochet_d'attelage": "Crochet d'attelage",
      Aucun_équipement_présent: 'Aucun équipement présent',
      Usé: 'Usé',
      'Usé(s)': 'Usé(s)',
      Usés: 'Usés',
      'Bien_(moins_de_30-60_secondes)': 'Bien (moins de 30-60 secondes)',
      'Très_bien_(moins_de_10_secondes)': 'Très bien (moins de 10 secondes)',
      'Non_fonctionnelle_(on_l_attend_encore_depuis_mai)':
        "Non fonctionnelle (on l'attend encore depuis mai)",
      Correcte: 'Correcte',
      Insuffisante: 'Insuffisante',
      Trop_élevée: 'Trop élevée',
      Trop_élevé: 'Trop élevée',
      Présence_d_un_ou_plusieurs_codes: "Présence d'un ou plusieurs codes",
      'One or more error code': "Présence d'un ou plusieurs codes",
      Aucun: 'Aucun',
      Hiver_clouté: 'Hiver clouté',
      '4_saisons': '4 saisons',
      Bonne: 'Bonne',
      Mauvaise: 'Mauvaise',
      Aucun_tapis_inclus: 'Aucun tapis inclus',
      extra: 'supplémentaire',
      extra2: 'supplémentaire',
      extra3: 'supplémentaire',
      extra4: 'supplémentaire',
      Non_présent: 'Non présent',
      À_changer: 'À changer',
      Non_vérifiable: 'Non vérifiable',
      Excellente_propreté_générale: 'Excellente propreté générale',
      Bonne_propreté_générale: 'Bonne propreté générale',
      Mauvaise_propreté_générale: 'Mauvaise propreté générale',
      Automatique: 'Automatique ',
      Manuelle: 'Manuelle',
      Automatic: 'Automatique',
      Manual: 'Manuelle',
      "Défaut_mineur_qui_ne_nécessite_pas_d'intervention_immédiate:aviser_le_garage_lors_du_prochain_entretien_de_contrôle_la courroie":
        'Défaut mineur qui ne nécessite pas d’intervention immédiate. Prévenir le garagiste lors du prochain entretien de vérifier la courroie.',
      'Degreasing shampoo and diagnostic':
        'Shampoing dégraissant et diagnostic',
      'Personalized recommendation': 'Recommandation personnalisée',

      // Traductions automatiques du formulaires
      // Besoin de garder les traductions 'pareilles' sinon le texte est traduit en anglais automatiquement
      'Partiellement opaque': 'Partiellement opaque',
      "Présence d'humidité": "Présence d'humidité",
      'Fissure(s)': 'Fissure(s)',
      Brisé: 'Brisé',
      Rayures: 'Rayures',
      Rouille: 'Rouille',
      'Éclat(s)': 'Éclat(s)',
      'Enfoncé(e)': 'Enfoncé(e)',
      'Rouille majeure': 'Rouille majeure',
      'Rouille mineure': 'Rouille mineure',
      'Usé(e)(s)': 'Usé(e)(s)',
      'Manquant(e)': 'Manquant(e)',
      Déformation: 'Déformation',
      'Traces de pinces': 'Traces de pinces',
      Trous: 'Trous',
      Sèche: 'Sèche',
      'Problème majeur': 'Problème majeur',
      'Problème mineur': 'Problème mineur',
      'Droit non-fonctionnel': 'Droit non-fonctionnel',
      'Gauche non-fonctionnel': 'Gauche non-fonctionnel',
      'Les deux non-fonctionnels': 'Les deux non-fonctionnels',
      'Le vendeur refuse': 'Le vendeur refuse',
      'Condition non sécuritaire': 'Condition non sécuritaire',
      'Condition administrative non conforme':
        'Condition administrative non conforme',
      Tabac: 'Tabac',
      Animaux: 'Animaux',
      Déchirures: 'Déchirures',
      'Tache(s)': 'Tache(s)',
      'Fuite mineure': 'Fuite mineure',
      'Bruit de tôle': 'Bruit de tôle',
      'Fuite majeure': 'Fuite majeure',
      'Détérioration majeure': 'Détérioration majeure',
      'Lave glace défectueux': 'Lave glace défectueux',
      'Balais essuie-glace défectueux': 'Balais essuie-glace défectueux',
      Dérive: 'Dérive',
      'Volant non aligné': 'Volant non aligné',
      'Comportement aléatoire': 'Comportement aléatoire',
      mineur: 'mineur',
      majeur: 'majeur',
      avant: 'avant',
      arrière: 'arrière',
      'Entretien de frein': 'Entretien de frein',
      Remplacement: 'Remplacement',
      Cognement: 'Cognement',
      Grincement: 'Grincement',
      'Avant gauche': 'Avant gauche',
      'Avant droit': 'Avant droit',
      'Non discernable': 'Non discernable',
      Oxydation: 'Oxydation',
      Gauche: 'Gauche',
      gauche: 'gauche',
      Droite: 'Droite',
      droite: 'droite',
      'Ne chauffe pas': 'Ne chauffe pas',
      Surchauffe: 'Surchauffe',
      Hésitation: 'Hésitation',
      "Raté d'allumage": "Raté d'allumage",
      'Manque de puissance': 'Manque de puissance',
      Sillement: 'Sillement',
      Grondement: 'Grondement',
      'Sur ralenti': 'Vibration',
      'En accélération': 'Vibration',
      Difficile: 'Difficile',
      Glissement: 'Glissement',
      Latence: 'Latence',
      'Pédale haute (manuelle)': 'Pédale haute (manuelle)',
      '4 roues': '4 roues',
      '6 roues': '6 roues',
      'Condition normale': 'Condition normale',
      'Mauvaise condition': 'Mauvaise condition',
      'Trop élevé': 'Trop élevé',
      Insuffisant: 'Insuffisant',
      "Présence d'odeur": "Présence d'odeur",
      Aucune: 'Aucune',
      'Non présent': 'N/A',
      Permanente: 'Permanente',
      'Validé(e)': 'Validé(e)',
      Anomalie: 'Anomalie',
      'Non vérifié(e)': 'Non vérifié(e)',
      Bruit: 'Bruit',
      Jeu: 'Jeu',
      'Point dur': 'Point dur',
      'Peu de taches': 'Peu de taches',
      'Beaucoup de taches': 'Beaucoup de taches',
      'Peu de déchirures': 'Peu de déchirures',
      'Beaucoup de déchirures': 'Beaucoup de déchirures',
      'Excellente propreté générale': 'Excellente propreté générale',
      'Bonne propreté générale': 'Bonne propreté générale',
      'Mauvaise propreté générale': 'Mauvaise propreté générale',
      '2 roues motrices': '2 roues motrices',
      '4 roues motrices': '4 roues motrices',
      'Pneu de secours': 'Pneu de secours',
      'Kit de changement de pneu': 'Kit de changement de pneu',
      'Kit anti-crevaison': 'Kit anti-crevaison',
      "Crochet d'attelage": "Crochet d'attelage",
      'Sièges chauffants avants': 'Sièges chauffants avants',
      'Sièges chauffants arrières': 'Sièges chauffants arrières',
      'Toit ouvrant': 'Toit ouvrant',
      'Filet de retenue': 'Filet de retenue',
      'Film protecteur de carrosserie': 'Film protecteur de carrosserie',
      'Barres de toit': 'Barres de toit',
      'Coffre de toit': 'Coffre de toit',
      'Garde boue': 'Garde boue',
      'Déflecteurs de vitres': 'Déflecteurs de vitres',
      'Déflecteur capot': 'Déflecteur capot',
      'Démarreur à distance': 'Démarreur à distance',
      'Aucun équipement présent': 'Aucun équipement présent',
      Autre: 'Autre',
      'Shampoing dégraissant et diagnostic':
        'Degreasing shampoo and diagnostic',
      'Recommandation personnalisée': 'Personalized recommendation',

      'Partially opaque': 'Partiellement opaque',
      Humidity: "Présence d'humidité",
      Cracked: 'Fissure(s)',
      Broken: 'Brisé',
      'Scratch(es)': 'Rayures',
      Rust: 'Rouille',
      'Stone chip': 'Éclat(s)',
      Dent: 'Enfoncé(e)',
      'Major Rust': 'Rouille majeure',
      'Minor Rust': 'Rouille mineure',
      Worn: 'Usé(e)(s)',
      Missing: 'Manquant(e)',
      Deformed: 'Déformation',
      'Traces of pliers': 'Traces de pinces',
      Holes: 'Trous',
      Dry: 'Sèche',
      'Major problem': 'Problème majeur',
      'Minor problem': 'Problème mineur',
      'Driver side': 'Droit non-fonctionnel',
      'Passenger side': 'Gauche non-fonctionnel',
      'Both side': 'Les deux non-fonctionnels',
      'The seller refuses': 'Le vendeur refuse',
      'Unsafe condition': 'Condition non sécuritaire',
      'Invalid administrative condition':
        'Condition administrative non conforme',
      Tobacco: 'Tabac',
      Pets: 'Animaux',
      Tears: 'Déchirures',
      Stains: 'Tache(s)',
      'Minor leak': 'Fuite mineure',
      'Major leak': 'Fuite majeure',
      'Heatshield noise': 'Bruit de tôle',
      'Major deterioration': 'Détérioration majeure',
      'Windshield washer': 'Lave glace défectueux',
      'Wiper blades': 'Balais essuie-glace défectueux',
      Drift: 'Dérive',
      'Steering wheel unaligned': 'Volant non aligné',
      'Random behavior': 'Comportement aléatoire',
      minor: 'mineur',
      major: 'majeur',
      front: 'avant',
      rear: 'arrière',
      'Brake maintenance': 'Entretien de frein',
      Replacement: 'Remplacement',
      Knocking: 'Cognement',
      Creaking: 'Grincement',
      'Front left': 'Avant gauche',
      'Front right': 'Avant droit',
      'not discernible': 'non discernable',
      Oxidation: 'Oxydation',
      Left: 'Gauche',
      left: 'gauche',
      Right: 'Droit',
      right: 'droit',
      'Does not heat up': 'Ne chauffe pas',
      Overheats: 'Surchauffe',
      Hesitation: 'Hésitation',
      Misfire: "Raté d'allumage",
      'Lack of power': 'Manque de puissance',
      Buzzing: 'Sillement',
      Rumbling: 'Grondement',
      'While slowing down': 'Vibration',
      'During acceleration': 'Vibration',
      Hard: 'Difficile',
      Slippage: 'Glissement',
      Latency: 'Latence',
      'High pedal (manual)': 'Pédale haute (manuelle)',
      '4 Wheels': '4 roues',
      '6 Wheels': '6 roues',
      'Normal condition': 'Condition normale',
      'Bad condition': 'Mauvaise condition',
      'Too high': 'Trop élevé',
      'Too low': 'Insuffisant',
      'Strong smell': "Présence d'odeur",
      None: 'Aucune',
      'N/A': 'Non présent',
      Permanent: 'Permanente',
      Validated: 'Validé(e)',
      Anomaly: 'Anomalie',
      'Not verified': 'Non vérifié(e)',
      'Not verifiable': 'Non vérifiable',
      Noise: 'Bruit',
      Play: 'Jeu',
      'Hard to turn': 'Point dur',
      'Few stains': 'Peu de taches',
      'Many stains': 'Beaucoup de taches',
      'Few tears': 'Peu de déchirures',
      'Many tears': 'Beaucoup de déchirues',
      'Excellent general cleanliness': 'Excellente propreté générale',
      'Good general cleanliness': 'Bonne propreté générale',
      'Bad general cleanliness': 'Mauvaise propreté générale',
      '2 wheel drive': '2 roues motrices',
      '4 wheel drive': '4 roues motrices',
      'Spare wheel': 'Pneu de secours',
      'Spare wheels tools kit': 'Kit de changement de pneu',
      'Puncture repair kit': 'Kit anti-crevaison',
      'Trailer hook': "Crochet d'attelage",
      'Heated front seats': 'Sièges chauffants avants',
      'Heated back seats': 'Sièges chauffants arrières',
      Sunroof: 'Toit ouvrant',
      'Cargo net': 'Filet de retenue',
      'Paint protection film': 'Film protecteur de carrosserie',
      'Roof rack system': 'Barres de toit',
      'Roof box': 'Coffre de toit',
      'Mud guards': 'Garde boue',
      'Side window deflectors': 'Déflecteurs de vitres',
      'Hood deflector': 'Déflecteur capot',
      'Remote starter': 'Démarreur à distance',
      Nothing: 'Aucun équipement présent',
      Other: 'Autre',
      'Ventilation non fonctionnelle': 'Ventilation non fonctionnelle',
      'Ventilation partiellement non fonctionnelle':
        'Ventilation partiellement non fonctionnelle',
      "Absence d'air chaud": "Absence d'air chaud",
      'Nonfunctional ventilation': 'Ventilation non fonctionnelle',
      'Partially nonfunctional ventilation':
        'Ventilation partiellement non fonctionnelle',
      'No hot air': "Absence d'air chaud",
      'Tear(s)': 'Déchiré(e)',
      'Disjointed seam(s)': 'Décousu(e)',
      'Déchiré(e)': 'Déchiré(e)',
      'Décousu(e)': 'Décousu(e)',
    },
    types: {
      title: "Type d'inspection",
      standard: ['Inspection standard', '- 189$'],
      vanPickupRV: 'Fourgonnette, Camions & VR',
      electricPIH: ['Inspection Électrique & Hybride Rechargeable', '- 209$'],
      premium: 'Prestige',
      classicExotic: 'Exotique & Collection',
    },
  },
  form: {
    confirmedUpload: 'Photo reçue',
  },
  business: {
    searchVin: 'Recherche par NIV',
    searchCity: 'Recherche par ville',
    inspection: 'Toutes les inspections',
    tab: {
      batch: 'Inspections en lots',
      inspection: 'Inspections individuelles',
      archivedInspection: 'Inspections archivées',
    },
    field: {
      date: 'Date',
      batchTitle: 'Titre du lot',
      inspectionTitle: 'Marque | NIV',
      status: 'Statut',
      remove: 'Retirer',
      location: 'Adresse',
      city: 'Ville',
      number: 'No.',
      numberInspection: "Nombre d'inspections",
      amount: 'Montant (avant taxe)',
      report: 'Rapport',
      notFinished: 'En attente...',
      action: 'Action',
      archive: 'Archiver',
      unarchive: 'Désarchiver',
      vin: 'NIV',
    },
    status: {
      waiting: 'En attente',
      started: 'Commencée',
      completed: 'Complété',
      ongoing: 'En cours',
      accepted: 'Acceptée',
      confirmed: 'Confirmée',
      all: 'Toutes',
      programmed: 'Programmé',
    },
    modal: {
      batchTitle: 'Voulez-vous retirer ce lot :',
      inspectionRemoveTitle: 'Voulez-vous retirer cette inspection?',
      inspectionArchiveTitle: 'Voulez-vous archiver cette inspection?',
      inspectionUnarchiveTitle: 'Voulez-vous désarchiver cette inspection?',
      remove: 'Retirer',
      cancel: 'Annuler',
      archive: 'Archiver',
      unarchive: 'Désarchiver',
    },
    snackBar: {
      remove: 'Retiré !',
      upload: 'Téléchargé !',
      create: 'Le lot a été créé !',
      archive: 'Archivé !',
      unarchive: 'Désarchivé !',
    },
    navbar: {
      inspection: "Statuts d'inspections",
      upload: "Télécharger un lot d'inspections",
      book: 'Réserver une inspection',
      account: 'Statut de compte',
      logout: 'Déconnexion',
      settings: 'Paramètres',
    },
    upload: "Télécharger un lot d'inspections",
    dragAndDrop: {
      title: 'Glisser & déposer le fichier CSV',
      or: 'ou',
      browse: 'Télécharger le fichier',
    },
    form: {
      batch: 'Titre du lot',
      batchHint: '*Ajouter un titre sur le lot',
      email: 'Adresse courriel',
      emailHint:
        "*Ajouter l'adresse courriel afin de recevoir les rapports d'inspection",
      emailError: "L'adresse courriel est invalide",
      submit: 'Soumettre',
    },
    template: '*Se référer au modèle de CSV suivant :',
    inspectionTypeOptions:
      "Pour les options de <b>type d'inspection</b>, veuillez choisir entre <b>PSI</b>, <b>Complete</b> ou <b>TroubleShoot</b>.",
    account: {
      title: 'Statut de compte',
      costSubtitle: 'Coût cumulatif pour le cycle de facturation actuel',
      invoiceSubtitle: 'Toutes les factures',
    },
    error: {
      noBatchFound: "Aucun lot téléchargé. Téléchargez un lot d'inspections.",
      errorBatch: 'Aucun lot trouvé. SVP contactez CarInspect.',
      noInspectionFound:
        'Aucune inspection téléchargée. Téléchargez une inspection.',
      errorInspection: 'Aucune inspection trouvée. SVP contactez CarInspect.',
      amountNotFound: 'Non trouvé',
      billNotFound: 'Aucune facture trouvé. SVP contactez CarInspect.',
      wrongFileType: 'Mauvais ficher : CSV requis',
      noFile: 'Aucun fichier téléchargé',
      uploadError: 'On a rencontré les erreurs suivantes :',
    },
    tooltip: 'Suppression est désactivé',
    formInspection: {
      bookInspection: 'Réserver une inspection',
      firstStep: {
        title: "Information de l'entreprise",
        email: 'Adresse courriel',
      },
      secondStep: {
        title: 'Personne/Entreprise à contacter pour effectuer une inspection',
        name: 'Personne/Compagnie à contacter',
        address: 'Adresse',
        city: 'Ville',
        postal: 'Code postal',
        phone: {
          number: 'Numéro de téléphone',
          hint: 'Modèle : xxx-xxx-xxxx',
        },
      },
      thirdStep: {
        title: 'Informations sur le véhicule',
        make: 'Marque',
        inspectionType: "Type d'inspection",
        model: 'Modèle',
        year: 'Année',
        vin: "Numéro d'identification du véhicule NIV",
        vinErrorMessage: 'Le VIN doit comporter exactement 17 caractères',
        "vinNoSpacesErrorMessage": "Le VIN ne doit pas contenir d'espaces.",
        fulladdress:"Veuillez fournir l'adresse complète et choisir l'une des options suggérées."
      },
      fourthStep: {
        title: 'Informations complémentaires',
        comment: 'Commentaire',
        conditions:
          "En cochant cette case, je consens à être facturé aux tarifs entendus dans le contrat et que, l'inspection CarInspect répondra aux termes entendus dans ledit contrat.",
      },
      next: 'Continuer',
      back: 'Retour',
      submit: "Réserver l'inspection",
      success: "L' inspection a été créée !",
      failure: "L' inspection a été refusée",
    },
  },
};
