import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  public jwtHelper = new JwtHelperService();

  constructor(public auth: AuthService, public router: Router, private authService: AuthService) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const expectedRole = route.data['expectedRole'];
    const redirectTarget = route.data['redirectTarget'];
    const isAuthenticated = await this.auth.isAuthenticated();
    const token = await this.auth.token();
    const user = this.jwtHelper.decodeToken(token || '');

    if (!isAuthenticated || user.role !== expectedRole) {
      let target = redirectTarget ?? '/login';
      return this.router.parseUrl(target);
    }
    return true;
  }
}
