<ng-container *ngIf="!authenticationComponent.isLoading; else loading">
  <div class="input_password">
    <h6>{{ 'general.password' | translate }}</h6>
    <input id="password_input_box" type="password" [(ngModel)]="authenticationComponent.password"
           (keydown.enter)="authenticationComponent.loginB2b()">
  </div>

  <div class="c-buttons">
    <div class="-center -noflex  flex-column">
      <div>
        <p>{{ 'authentication.B2B_forgot_password' | translate }}</p>
        <p id="auth-contact">{{ 'authentication.B2B_contact' | translate }}&nbsp;<a class="-center forgot"> inspections@kiwiz.ca</a></p>
      </div>
    </div>
      <button id="submit_btn" class="-secondary -large -outline" (click)="authenticationComponent.loginB2b()">{{
      'authentication.connect' | translate
      }}</button>
    <div class="translation-container">
      <a (click)="switchLanguage('fr')" class="translate-title" [class.translate-active]="isFrench">FR</a>
      &nbsp;<span class="translate-title">|</span>&nbsp;
      <a (click)="switchLanguage('en')" class="translate-title" [class.translate-active]="!isFrench">EN</a>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <app-loading-secondary></app-loading-secondary>
</ng-template>
